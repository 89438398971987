import React, { useCallback, useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { ButtonPrimary } from "../../../../Components/Button";
import { FunctionalManageFadeOut } from "../../../../services/manageFadeOut";
import { createDocumentCategory, deleteDocumentCategory, fetchCorporateAccount } from "../../../../redux/actions";
import { token } from "../../../../modules/auth";

const mapStateToProps = (state) => {
  return {
    ...state.corporateAccount,
    token: token(state),
    corporateDocument: {
      ...state.corporateDocument,
    },
  };
};

const mapDispatchToProps = {
  createDocumentCategory,
  deleteDocumentCategory,
  fetchCorporateAccount,
};

let CorporateAccountDocumentCategoryAddContainer = ({
                                                      corporateAccount,
                                                      match,
                                                      token,
                                                      createDocumentCategory,
                                                      deleteDocumentCategory,
                                                      fetchCorporateAccount,
                                                    }) => {
  const [categories, setCategories] = useState(corporateAccount?.categories);
  const [newCategory, setNewCategory] = useState("");
  const [filesAffected, setFilesAffected] = useState([]);
  const [state, setState] = useState({
    categorySuccess: false,
    error: false,
  });

  useEffect(() => {
    fetchCorporateAccount(match.params.slug, token);
  }, []);

  useEffect(() => {
    if (corporateAccount) {
      setCategories(corporateAccount.categories);
    }
  }, [corporateAccount]);

  const onNewCategory = useCallback((e) => {
    setNewCategory(e.target.value);
  }, []);

  const onAddNewCategory = useCallback(async (name) => {
    return createDocumentCategory(match.params.slug, token, { name: name })
      .then((response) => {
        FunctionalManageFadeOut("categorySuccess", setState);
        return Promise.resolve();
      })
      .catch((error) => {
        FunctionalManageFadeOut("error", setState);
      });
  }, []);

  const onDeleteCategory = async (category) => {
    if (window.confirm("Are you sure you want to delete the category " + category.name + "?")) {
      return deleteDocumentCategory(match.params.slug, category.id, token).then((response) => {
        FunctionalManageFadeOut("categorySuccess", setState);
        return Promise.resolve();
      }).catch(error => {
        FunctionalManageFadeOut("error", setState);
      });
    }
  };


  return (
    <div className={"bg-white p-5 mt-10"}>
      <div>
        <h3 className={"mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
          Document Categories
        </h3>
        <p>Define the categories to store Useful Documents under, for Users accessing this Corporate Account</p>
        {filesAffected.length > 0 ?
          <p className={"text-red"}>These files have had their category removed: {filesAffected.join(", ")}</p> : null
        }
        <ul className={"list-reset mb-5"}>
          {categories && categories.map(function(category) {
            return (
              <li key={category.id} className={"mt-1"}>
                <strong>{category.name}</strong>
                <i className="pt-3 ml-2 fas fa-trash text-red cursor-pointer"
                   onClick={() => {
                     let affectedFiles = corporateAccount.documents.filter((document) => document.category_names.filter((category_name) => category_name === category.name).length > 0);
                     setFilesAffected(affectedFiles.map((file) => file.name));
                     onDeleteCategory(category);
                   }} />
              </li>
            );
          })}
        </ul>

        <div className="row">
          <div className={"col col-w-full add-category-container"}>
            <div className="field mb-4">
              <label className={` mb-1 block font-normal font-heading uppercase`}>{"Add A New Category"}</label>
              <input
                value={newCategory}
                onChange={onNewCategory}
                className={` px-2 py-0 border w-full outline-none font-normal text-black text-sm focus:border-primary bg-white rounded-none appearance-none border-grey-md h-10`}
                autoComplete="off"
              />
            </div>
            <ButtonPrimary onClick={() => {
              onAddNewCategory(newCategory).then((response) => {
                setNewCategory("");
              });
            }} classes="mt-1 ml-2">Add</ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  );
};


CorporateAccountDocumentCategoryAddContainer = reduxForm({
  form: "corporateAccountDocumentCategoryForm",
  enableReinitialize: true,
})(CorporateAccountDocumentCategoryAddContainer);

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountDocumentCategoryAddContainer);
