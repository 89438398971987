import React from "react";
import { connect } from "react-redux";
import { agencyObject, companyObject } from "../modules/permissions";
import { DEFAULT_AGENCY_CONTACT, EDGE_ENQUIRIES_EMAIL, EDGE_PHONE_NUMBER } from "../constants";

class CorporateContactUs extends React.Component {
  render() {
    const { agency = DEFAULT_AGENCY_CONTACT } = this.props;

    return (
      <div className="container">
        <div className="bg-white m-1 mt-4 p-5">
          <div className="clearfix">
            <h1 className="my-6">Contact {agency.name}</h1>
            <p>For event bookings and support with Corporate EDGE, contact the team today: </p>
            {agency.phone_number ? <p>
              <a href={`tel:${agency.phone_number.replace(/\s/g, "")}`} className="font-normal">
                {agency.phone_number}
              </a>
            </p> : null}
            {agency.email_address ? <p>
              <p>
                <a href={`mailto:${agency.email_address}`} className="font-normal">
                  {agency.email_address}
                </a>
              </p>
            </p> : null}

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let agency = {};
  if (agencyObject(state.auth).length > 0) {
    agency = agencyObject(state.auth);
  } else if (companyObject(state.auth)) {
    agency = companyObject(state.auth).agency_id ? companyObject(state.auth).agency : {};
  }
  return {
    agency: agency,
  };
};

export default connect(mapStateToProps, null)(CorporateContactUs);
