import React from "react";
import axios from 'axios';
import * as ROUTES from '../../Router/corporate_routes';
import {
  AGENCY_USER_INVITE_RECEIVED_ERROR,
  AGENCY_USER_INVITE_RECEIVED_REQUEST,
  AGENCY_USER_INVITE_REQUEST,
  COMPANY_USER_INVITE_RECEIVED_ERROR,
  COMPANY_USER_INVITE_RECEIVED_REQUEST,
  COMPANY_USER_INVITE_REQUEST,
  REGISTER_INVITED_USER_RECEIVED_ERROR,
  REGISTER_INVITED_USER_RECEIVED_REQUEST,
  REGISTER_INVITED_USER_REQUEST,
  USER_INVITE_RECEIVED_ERROR,
  USER_INVITE_RECEIVED_REQUEST,
  USER_INVITE_REQUEST,
  USER_INVITE_UNLOAD,
  USER_INVITES_RECEIVED_ERROR,
  USER_INVITES_RECEIVED_REQUEST,
  USER_INVITES_REQUEST,
  USER_RESEND_INVITE_RECEIVED_ERROR,
  USER_RESEND_INVITE_RECEIVED_REQUEST,
  USER_RESEND_INVITE_REQUEST,
  USER_INVITES_UNLOAD, DELETE_INVITE_REQUEST, DELETE_INVITE_RECEIVED_REQUEST, DELETE_INVITE_RECEIVED_ERROR
} from "../reducers/types";
import handleErrors from "./utils";

export const userInviteRequest = () => ({
  type: USER_INVITE_REQUEST
});

export const userInviteReceivedRequest = data => ({
  type: USER_INVITE_RECEIVED_REQUEST,
  data
});

export const userInviteReceivedError = error => ({
  type: USER_INVITE_RECEIVED_ERROR,
  error
});

export const userInviteUnload = () => ({
  type: USER_INVITE_UNLOAD
});

export const agencyUserInviteRequest = () => ({
  type: AGENCY_USER_INVITE_REQUEST
});

export const agencyUserInviteReceivedRequest = data => ({
  type: AGENCY_USER_INVITE_RECEIVED_REQUEST,
  data
});

export const agencyUserInvitedReceivedError = error => ({
  type: AGENCY_USER_INVITE_RECEIVED_ERROR,
  error
});

export const companyUserInvite = () => ({
  type: COMPANY_USER_INVITE_REQUEST
});

export const companyUserInviteReceivedRequest = data => ({
  type: COMPANY_USER_INVITE_RECEIVED_REQUEST,
  data
});

export const companyUserInviteReceiveError = error => ({
  type: COMPANY_USER_INVITE_RECEIVED_ERROR,
  error
});

export const userInvitesRequest = () => ({
  type: USER_INVITES_REQUEST
});

export const userInvitesReceivedRequest = data => ({
  type: USER_INVITES_RECEIVED_REQUEST,
  data
});

export const userInvitesReceivedError = error => ({
  type: USER_INVITES_RECEIVED_ERROR,
  error
});

export const resendInviteRequest = () => ({
  type: USER_RESEND_INVITE_REQUEST
});

export const resendInviteReceivedRequest = data => ({
  type: USER_RESEND_INVITE_RECEIVED_REQUEST,
  data
});

export const resendInviteReceivedError = error => ({
  type: USER_RESEND_INVITE_RECEIVED_ERROR,
  error
});

export const registerInvitedUserRequest = () => ({
  type: REGISTER_INVITED_USER_REQUEST,
});

export const registerInvitedUserReceivedRequest = data => ({
  type: REGISTER_INVITED_USER_RECEIVED_REQUEST,
  data
});

export const registerInvitedUserReceivedError = error => ({
  type: REGISTER_INVITED_USER_RECEIVED_ERROR,
  error
});

export const userInvitesUnload = () => ({
  type: USER_INVITES_UNLOAD
});

export const deleteInviteRequest = () => ({
  type: DELETE_INVITE_REQUEST
});

export const deleteInviteReceivedRequest = data => ({
  type: DELETE_INVITE_RECEIVED_REQUEST,
  data
});

export const deleteInviteReceivedError = error => ({
  type: DELETE_INVITE_RECEIVED_ERROR,
  error
});

export const registerInvitedUser = (invite_code, is_existing_user, token,  formValues) => {
  return (dispatch) => {
    dispatch(registerInvitedUserRequest());
    return axios.post(window.API + ROUTES.API.USER_INVITES.REGISTER.replace(':invite_code', invite_code).replace(':register', is_existing_user ? 'join' : 'register'), formValues,
        is_existing_user ? {
          headers: {Authorization: `Bearer ${token}`}
        } : null
        )
      .then(response => {
        dispatch(registerInvitedUserReceivedRequest(response.data))
      }).catch(error => {
        dispatch(registerInvitedUserReceivedError(error))
        handleErrors(error);
      })
  }
}

export const resendInvite = (invite_code, token) => {
  return (dispatch) => {
    dispatch(resendInviteRequest());
    return axios.post(window.API + ROUTES.API.USER_INVITES.RESEND.replace(':invite_code', invite_code), '', {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(resendInviteReceivedRequest(response.data))
    }).catch(error => {
      dispatch(resendInviteReceivedError(error))
    })
  }
}

export const deleteInvite = (invite_code, token) => {
  return (dispatch) => {
    dispatch(deleteInviteRequest());
    return axios.delete(window.API + ROUTES.API.USER_INVITES.SINGLE.replace(':invite_code', invite_code), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(deleteInviteReceivedRequest(response.data))
    }).catch(error => {
      dispatch(deleteInviteReceivedError(error))
    })
  }
}

export const fetchCompanyUserInvites = (id, token) => {
  return (dispatch) => {
    dispatch(userInvitesRequest())
    return axios.get(window.API + ROUTES.API.COMPANY.USER_INVITES.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(userInvitesReceivedRequest(response.data))
    }).catch(error => dispatch(userInvitesReceivedError(error)))
  }
}

export const fetchUserInvite = (invite_code) => {
  return (dispatch) => {
    dispatch(userInviteRequest());
    return axios.get(window.API + ROUTES.API.USER_INVITES.SINGLE.replace(':invite_code', invite_code))
      .then(response => {
        dispatch(userInviteReceivedRequest(response.data))
      }).catch(error => dispatch(userInviteReceivedError(error)));
  }
}

export const fetchAgencyUserInvites = (id, token) => {
  return (dispatch) => {
    dispatch(userInvitesRequest())
    return axios.get(window.API + ROUTES.API.AGENCY.USER_INVITES.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(userInvitesReceivedRequest(response.data))
    }).catch(error => dispatch(userInvitesReceivedError(error)))
  }
}

export const sendAgencyUserInvite = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(agencyUserInviteRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.INVITE_USER.replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(agencyUserInviteReceivedRequest(response.data))
    }).catch(error => {
      dispatch(agencyUserInvitedReceivedError(error))
      handleErrors(error)
      return Promise.reject(error)
    })
  }
}

export const sendCompanyUserInvite = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(companyUserInvite());
    return axios.post(window.API + ROUTES.API.COMPANY.INVITE_USER.replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(companyUserInviteReceivedRequest(response.data))
    }).catch(error => {
      dispatch(companyUserInviteReceiveError(error))
      handleErrors(error)
      return Promise.reject(error)
    })
  }
}
