import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import debounce from "lodash/debounce";
import { Field, clearFields, change, getFormValues } from "redux-form";
import { token } from "modules/auth";
import Input from "Components/Form/Input";
import { Input as UiInput } from "ui";
import { ButtonSpan } from "Components/Button";
import LiveSearchResponse from "./LiveSearchResponse";
import propTypes, { defaultProps } from "./propTypes";
import search from "./search";
import select from "./select";
import {isArray} from "lodash";

class LiveSearch extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  state = {
    results: undefined,
    searching: false,
    message: undefined,
    chosen: false, // important for componentDidUpdate
    shouldClear: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { query, queryId, onNoResults, setResultCount = ()=>{}, shouldClearSearches = false, clearValues } = this.props;

    if (this.state.results && isArray(this.state.results)) {
      setResultCount(this.state.results.length);
    }
    if(prevProps.clearValues !== clearValues)
    {
      this.clear();
    }
    // this catches hydrated values
    if (this.state.chosen && prevProps.query === query && shouldClearSearches) {
      this.clear();
    }
    if ( !this.state.shouldClear && shouldClearSearches) {
//      console.log('set state.shouldClear = true because props.shouldClear is true and state.shouldClear is false');
      this.setState({shouldClear:true});
    }
    if ( prevState.shouldClear !== this.state.shouldClear && this.state.shouldClear) {
//      console.log('set state.shouldClear = false because prevState.shouldClear <> this.state.shouldClear and this.state.shouldClear is true');
      this.setState({shouldClear:false}, this.clear);
    }
    if (queryId && !prevProps.queryId) {
      this.setState({
        results: undefined,
        message: undefined,
        searching: false,
        chosen: true,
      });
      if (onNoResults && !this.state.chosen) {
        onNoResults(false)
      }
      // this identifies new queries, when the field is updated on select
    } else if (query && prevProps.query !== query && !this.state.chosen) {
      // when all text from field is wiped on SS this doesnt get hit due to first bit of if above.
      // it caused old venue type id to linger in form and break search
      if (onNoResults) {
        onNoResults(false)
      }
      this.search(query);
    } else if ((isArray(prevState.results) && prevState.results.length < 1) && onNoResults && !this.state.searching ) {
      onNoResults(true)
    }
  }

  search = debounce(value => {
    if (this.state.cancelToken) this.state.cancelToken();
    search(value, this.props, {
      minLengthWarning: () =>
        this.setState({
          message: "Please enter at least 3 characters",
        }),
      fetching: () =>
        this.setState({
          searching: true,
          message: undefined,
        }),
      results: results =>
        this.setState({
          results,
          searching: false,
          message: results.length ? undefined : "No results found",
        }),
      error: e => {
        if (e) {
          this.setState({
            results: undefined,
            searching: false,
            message: "Sorry an error occurred",
          });
        }
      },
      cancelToken: cancelToken =>
        this.setState({
          cancelToken,
        }),
    });
  }, 500);

  select = result => {
    this.setState({
      results: undefined,
      message: undefined,
      searching: false,
      chosen: true, // prevent search triggering when value is updated on select
    });
    select(result, this.props);
  };

  // helper for allowing searches when focusing back in
  enable = () => {
    this.setState({
      chosen: false, // used in componentDidUpdate to prevent searches
    });
  };

  // helper for removing warnings on blur
  clearMessage = () => {
    this.setState({
      message: undefined,
    });
  };

  clear = () => {
    this.setState({
      results: undefined,
      searching: false,
      message: undefined,
      chosen: false,
    });
    this.props.change(this.props.formId, this.props.name, null);
    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  // I think we can get away with doing this on index!
  removeMultiValue = index => {
    const newValues = [...this.props.multiValues];
    const newIdValues = [...this.props.multiIdValues];
    newValues.splice(index, 1);
    newIdValues.splice(index, 1);
    this.props.change(this.props.formId, `${this.props.multi}_text`, newValues);
    this.props.change(this.props.formId, this.props.multi, newIdValues);
    this.props.onRemove && this.props.onRemove(newValues, newIdValues);
  };

  render() {
    const {
      id,
      name,
      label,
      placeholder,
      labelClassName,
      multi,
      multiSearchClass,
      multiResultsClass,
      multiValues,
      wrapperClassName,
      fieldClassName,
      validate,
      query,
      getText,
      icon,
      white,
      tall,
      getLinkTo,
      disabled = false,
      clearValues
    } = this.props;

    let leftIcon = icon;
    if (leftIcon && this.state.searching) {
      leftIcon = "/img/loading.svg";
    }
    // console.log(fieldClassName);
    const liveSearch = () => (
      <div className="relative">
        <Field
          id={id}
          props={{disabled}}
          autoComplete="off"
          name={name}
          label={label}
          component={this.props.ui ? UiInput : Input}
          validate={validate}
          onFocus={this.enable}
          onBlur={this.clearMessage}
          placeholder={placeholder}
          labelClassName={labelClassName}
          wrapperClassName={fieldClassName}
          icon={leftIcon}
          white={white}
          tall={tall}
          iconRight={
            !icon && this.state.searching ? "/img/loading.svg" : undefined
          }
        />
        {!this.state.searching && this.props.query && (
          <div
            className={`absolute pin-r pin-b pl-1 pr-1 mr-2 mb-${
              this.props.ui ? "1" : "2"
            }`}
          >
            <i
              className="fal fa-times pointer text-black"
              role="button"
              tabIndex="0"
              onClick={this.clear}
              onKeyPress={this.clear}
            />
          </div>
        )}
        <LiveSearchResponse
          results={this.state.results}
          message={this.state.message}
          getText={getText}
          getLinkTo={getLinkTo}
          query={query}
          select={this.select}
        />
      </div>
    );
    if (!multi) {
      return <div className={wrapperClassName}>{liveSearch()}</div>;
    }
    return (
      <div className={wrapperClassName || "flex flex-col md:flex-row"}>
        <div className={multiSearchClass || "md:w-72 flex-no-shrink"}>
          {liveSearch()}
        </div>
        <div className={multiResultsClass || "md:flex-grow md:pt-5 mb-5"}>
          <div className={multiResultsClass ? null : "md:bg-grey h-full"}>
            {multiValues.map((value, i) => (
              <ButtonSpan
                key={value}
                className="border border-grey-md bg-white font-normal text-xs p-1 rounded inline-block mr-1 mb-1"
                onClick={() => this.removeMultiValue(i)}
              >
                {value}
                <i className="fas fa-times ml-1" />
              </ButtonSpan>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => {
      const values = getFormValues(props.formId)(state);
      return {
        token: token(state),
        multiValues:
          values && values[`${props.multi}_text`]
            ? values[`${props.multi}_text`]
            : [],
        multiIdValues: values && values[props.multi] ? values[props.multi] : [],
        query: values ? values[props.name] : undefined,
        queryId: values ? values[`${props.name}_id`] : undefined,
      };
    },
    { clearFields, change }
  )(LiveSearch)
);
