import React, {Suspense, lazy, useState} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Public from "./Public";
import Corporate from "./Corporate";
import ScrollToTop from "Components/ScrollToTop";
import {LoadingHalfPage} from "../Components/Loading";
import SentryAppendDetails from "../Components/SentryAppendDetails";
import {RouteTracker} from "../modules/google-tag-manager";
import Seo from "Components/Seo";
import {useInterval} from "../hooks/useInterval";
import {getVersions} from "../services/versionService";

const Admin = lazy(() => import("./Admin" /* webpackChunkName: "admin" */));
const VersionCheck = () => {
    const [versions, setVersions] = useState({portal: false});
    useInterval(() => {
        if (window.EDGE_VERSION === 'dev') {
            return; // do nothing in dev mode.
        }
        getVersions().then(({result, error}) => {
            if (result) {
                setVersions(result);
            } else {
                console.error('Error fetching versions:', error);
            }
        });
    }, 1000 * 60 );
    if (window.EDGE_VERSION === 'dev') {
        return null;
    }
    //ignore -stage/-demo tag parts
    const checkVersion = window.EDGE_VERSION.split('-')[0];
    if (versions?.portal && versions.portal !== checkVersion && versions.portal !== 'unknown') {
        const updateHref = new URL(document.location.toString());
        updateHref.searchParams.set('v', versions.portal);
        return <div className={'text-center bg-brand font-bold p-4'}><a className={'text-white underline'} href={updateHref.toString()}>A new version is available - click here to update</a></div>
    }
    return null;
};

export default function Router() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                {window.EDGE_VERSION !== 'dev' ? <VersionCheck/> : null}
                <Seo/>
                <RouteTracker/>
                <SentryAppendDetails/>
                <Suspense fallback={<LoadingHalfPage/>}>
                    <Switch>
                        <Route path="/admin" render={props => <Admin {...props} />}/>
                        <Route path="/corporate" render={props => <Corporate {...props}  />}/>
                        <Route component={Public}/>
                    </Switch>
                </Suspense>
            </ScrollToTop>
        </BrowserRouter>
    );
}
