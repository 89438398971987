import React, { useEffect, useRef } from "react";

import { connect } from "react-redux";

import { isAgency, isSuperAgent, token } from "modules/auth";
import Loading from "../../../Components/Loading";
import AdminList from "../../../Components/AdminList";
import { isRolePlatformAdmin } from "../../../modules/permissions";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../Router/corporate_routes";
import AdminListActionButton from "../../../Components/AdminListActionButton";
import { fetchCorporateAccounts } from "../../../redux/actions";


let CorporateAccountList = ({
                              isFetching,
                              accounts,
                              isCompanyAdmin,
                              selectedAgencies,
                              fetchCorporateAccounts,
                              token,
                              isAgency,
                              isRolePlatformAdmin,
                              isSuperAgent,
                            }) => {
  const mountedRef = useRef();
  useEffect(() => {
    if (isSuperAgent && mountedRef.current) {
      fetchCorporateAccounts(token);
    }
  }, [selectedAgencies]);
  useEffect(() => {
    mountedRef.current = true;
  }, []);
  if (isFetching) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (Object.entries(accounts).length === 0 && accounts.constructor === Object) {
    return (
      <div>No corporate accounts available</div>
    );
  }

  let createAction = () => {
    if (isAgency && isSuperAgent && selectedAgencies.length !== 1) {
      return <p>You are viewing Multiple Agencies, Please reduce to one to create a new Corporate Account</p>;
    } else {
      return (
        <AdminListActionButton to={ROUTES.FRONTEND.CORPORATE.CREATE} text={`Add New`} />
      );
    }
    return null;
  };

  let headings = () => {
    return (
      <tr>
        <th>Actions</th>
        <th>Account Name</th>
        <th>Agency</th>
      </tr>
    );
  };

  let corporateList = () => {
    return accounts.data.sort((a, b) => a.account_name.localeCompare(b.account_name)).map((account) => {
      return (
        <tr key={account.id}>
          <td className="px-6 py-3 whitespace-nowrap">
            <div className="flex items-center">
              <div className="text-sm font-medium text-gray-900">
                <Link
                  to={ROUTES.FRONTEND.CORPORATE.EDIT.replace(":slug", account.id).replace(":section", isCompanyAdmin ? "manage-industry-events" : "overview")}
                  className={"text-black"}>
                  <i className="fal fa-edit px-3" />
                </Link>
                {(account.is_active === 0) && (
                  <i title="This Corporate Account is not active"
                     className="fal fa-eye-slash text-warning pr-3" />
                )}
              </div>
            </div>
          </td>
          <td>
            <div className="flex items-center">
              <div>
                <Link
                  to={ROUTES.FRONTEND.CORPORATE.EDIT.replace(":slug", account.id).replace(":section", isCompanyAdmin ? "manage-industry-events" : "overview")}
                  className={"text-black"}>
                  {account.account_name}
                </Link>
              </div>
            </div>
          </td>
          <td>
            <div className="flex items-center">
              <div>
                {account?.company?.agency?.name}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <AdminList
      title={"Corporate Accounts"}
      actions={
        <React.Fragment>
          {createAction()}
        </React.Fragment>
      }
      tableHeadings={headings()}
      tableList={corporateList()}
    />
  );

};

export default connect(state => ({
  token: token(state),
  selectedAgencies: state.selectedAgencies.selectedAgencies,
  isRolePlatformAdmin: isRolePlatformAdmin(state),
  isSuperAgent: isSuperAgent(state),
  isAgency: isAgency(state),
}), { fetchCorporateAccounts })(CorporateAccountList);
