import classNames from 'classnames';
import {object} from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {getWidth} from 'redux-window';
import {Link, withRouter} from 'react-router-dom';
import {ButtonSpan} from 'Components/Button';
import {userEmail, userName, userFirstName, isCorporate, isSuperAgent, isAgencyUser} from "modules/auth";
import mainItems, {corporateItems, getMenuItems, secondaryItems, corporateSecondaryItems} from 'modules/menu-items';
import HeaderWrap from './HeaderWrap';
import {corporateAccountObject, hasActiveCorporateAccount} from "../../modules/permissions";
import * as ROUTE from "../../Router/public_routes";
import {fetchSelectedAgencies} from "../../redux/actions";
import SuperAgencySelector from "./SuperAgencySelector";

const SubMenu = ({activeSection, auth, path, secondaryItems, corporateAccount}) => {
    const items = secondaryItems[activeSection];
    if (!items) {
        return null;
    }

    return (<>
        <div className="bg-light-black hidden xl:block">
            <div className="container">
                <div className="flex flex-wrap" style={{"paddingLeft": "125px"}}>
                    {getMenuItems(auth, items).map((item, index) => {
                        const itemPath = item.type === "corporate" && corporateAccount?.id ? item.path.replace(":slug", corporateAccount.id) : item.path;
                        const active = itemPath === path;
                        const className = classNames("text-sm block py-2 px-5 uppercase text-white", {
                            "ml-8": !index,
                            "ml-3": index,
                            "font-normal": !active,
                            "font-bold": active,
                            "bg-dark-grey": active,
                        },);
                        if (item.href) {
                            return (<a
                                href={item.href}
                                title={item.text}
                                target={item.newTab ? "_blank" : undefined}
                                rel={item.newTab ? "noreferrer noopener" : undefined}
                                className={className}
                                key={item.href}
                            >
                                {item.text}
                            </a>);
                        }
                        return (<Link key={item.path} to={itemPath} className={className}>
                            {item.text}
                        </Link>);
                    })}
                </div>
            </div>
        </div>
    </>);
};

const MainMenu = ({auth, mainItems, location}) => {
    return getMenuItems(auth, mainItems).map(item => {
        const active = location.pathname.startsWith(item.path);
        const className = classNames("text-sm flex items-end py-3 px-5 uppercase text-white", {
            "bg-brand": active, "font-normal": !active, "font-bold": active,
        },);

        return item.href ? (<a
            href={item.href}
            title={item.text}
            target={item.newTab ? "_blank" : undefined}
            rel={item.newTab ? "noreferrer noopener" : undefined}
            className={className}
            key={item.href}
        >
            {item.text}
        </a>) : (<Link
            key={item.path}
            to={item.path}
            className={className}
        >
            {item.text}
        </Link>);
    });

};


const AdminHeader = ({
                         desktop,
                         userEmail,
                         userFirstName,
                         auth,
                         location,
                         brand,
                         isCorporate,
                         corporateAccount,
                         hasActiveCorporateAccount,
                         isSuperAgent,
                         isAgencyUser
                     }) => {
    const hasBrand = !!brand.id;
    const AdminEDGELogo = hasActiveCorporateAccount && corporateAccount?.logo_link ? (corporateAccount?.logo_link ? corporateAccount?.cdn_path : "/img/CE-logo-alt.png") : "/img/logo-alt.png";
    const logoAlt = hasActiveCorporateAccount && corporateAccount?.logo_link ? corporateAccount.account_name + " logo" : "EDGE Venues logo";
    const companyName = auth.company ? auth.company.name : null;

    return (<HeaderWrap>
        {({adminMenuOpen, toggleAdminMenu}) => (<header className="bg-black">
            <div className="container flex justify-between md:flex-start">
                <Link to="/" style={{maxHeight: "130px", overflowY: "hidden"}}
                      className="flex flex-col justify-around">
                    {hasBrand ? (<>
                        <div
                            style={{
                                height: !desktop ? "42px" : "60px", width: !desktop ? "85px" : "125px",
                            }}
                        >
                            <img
                                src={brand.branding_image_url}
                                alt={`${brand.company_name} logo`}
                                style={{
                                    maxHeight: !desktop ? "42px" : "60px",
                                }}
                            />
                        </div>
                        <p className="mb-0 text-xs">
                            <span className="text-white">Powered by</span> EDGE Venues
                        </p>
                    </>) : (<img
                        src={AdminEDGELogo}
                        alt={logoAlt}
                        width={!desktop ? "110px" : "125px"}
                        height={!desktop ? "auto" : "60px"}
                        className="pt-3 my-5"
                    />)}
                </Link>
                {adminMenuOpen && <div className="pl-8 flex-1 hidden xl:flex">
                    <MainMenu auth={auth}
                              mainItems={(isCorporate && hasActiveCorporateAccount) ? corporateItems : mainItems}
                              location={location} desktop={desktop}/>
                </div>}

                <SuperAgencySelector />

                <nav className="py-3 pl-3 flex flex-col justify-between text-right text-sm">
                    <div className="text-white font-bold"
                         title={`${userEmail} ${companyName ? "(" + companyName + ")" : ""}`}>
                        Hello {auth.user && auth.user.is_sso_user ? userEmail : userFirstName}
                    </div>
                    <Link to="/admin/profile" className="text-white block">
                        <i className="mr-2 fas fa-user" title={`Version ${window.EDGE_VERSION}`}/>
                        <span className="hidden sm:inline">Your </span>Profile
                    </Link>
                    {isAgencyUser ? (<Link to={ROUTE.FRONTEND.ENQUIRY.NEW} className="text-white my-1 block">
                        <i className="fas fa-folder-plus pr-2"/>
                        New Enquiry
                    </Link>) : null}

                    <Link to="/logout" className="text-white block">
                        <i className="ml-6 mr-1 fas fa-sign-out-alt"/>
                        Logout
                    </Link>

                    <ButtonSpan
                        onClick={toggleAdminMenu}
                        className="text-white inline-block xl:hidden"
                    >
                        <i
                            className={`ml-2 fas fa-${adminMenuOpen ? "minus" : "plus"}`}
                        />
                        Menu
                    </ButtonSpan>
                </nav>
            </div>
            {adminMenuOpen && <div className="pl-8 flex-1 xl:hidden">

                <div className="container">
                    <MainMenu auth={auth} mainItems={isCorporate ? corporateItems : mainItems}
                              location={location}
                              desktop={desktop}/>
                </div>
            </div>}
            <div className="bg-brand h-1"/>
            {adminMenuOpen && desktop && location.pathname !== "/corporate/dashboard" && <SubMenu
                corporateAccount={corporateAccount}
                secondaryItems={isCorporate ? corporateSecondaryItems : secondaryItems}
                activeSection={location.pathname.split("/")[2]}
                auth={auth}
                path={location.pathname}
            />}
        </header>)}
    </HeaderWrap>);
};

AdminHeader.propTypes = {
    location: object.isRequired, auth: object.isRequired,
};

export default withRouter(connect(state => ({
    auth: state.auth,
    desktop: getWidth(state) >= 1200,
    userName: userName(state),
    userEmail: userEmail(state),
    userFirstName: userFirstName(state),
    brand: state.brand,
    isCorporate: isCorporate(state),
    corporateAccount: corporateAccountObject(state.auth),
    hasActiveCorporateAccount: hasActiveCorporateAccount(state.auth),
    isSuperAgent: isSuperAgent(state),
    isAgencyUser: isAgencyUser(state),
    token: state.auth.token,
}), {fetchSelectedAgencies})(AdminHeader),);

