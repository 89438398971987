import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useService from "hooks/useService";
import { getEnquiryById } from "services/rfpService";
import Loading from "Components/Loading";
import RfpBriefSection from "Components/RfpBriefSection";
import RfpProposals from "Components/RfpProposals";
import RfpTable from "Components/RfpTable";
import Warning from "Components/Warning";
import { getNotesForRfp } from "../../../redux/actions/notes";
import { connect, useDispatch, useSelector, useStore } from "react-redux";
import axios from "axios";
import { isAgency, isRoleTrinityEdgeRFP, token, token as tokenSelector } from "../../../modules/auth";
import {ButtonPrimary, ButtonPrimaryRed, ButtonRed} from "../../../Components/Button";
import { agencyObject, corporateAccountObject, isSuperAgent } from "../../../modules/permissions";
import { reduxForm, reset } from "redux-form";
import RFPCancelModalForm from "../../../Components/Rfp/RFPCancelModalForm";
import { useHistory } from "react-router";
import { ENQUIRY_STATUS } from "../../../constants";
import moment from "moment";
import RfpStatus from "../../../Components/RfpStatus";
import { corporateAccountBookingDesksUnload, fetchBookingDesks } from "../../../redux/actions";
import AuthorisationBanner from "../../../Components/AuthorisationBanner";
import RFPDeclineModalForm from "../../../Components/Rfp/RFPDeclineModalForm";
import RFPDenyMessage from "../../../Components/Rfp/RFPDenyMessage";
import ManagingAgentBanner from "../AgencyEdge/ManagingAgentBanner";

// THIS IS THE AGENT/USER VIEW
// TABLE -> BRIEF/PROPOSALS/ACTIONS MODALS

const YourRfps = (props) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeniedModal, setShowDeniedModal] = useState(false);
  const [submitting, setSubmitting] = useState("");
  const { id } = useParams();
  const { loading, data, error } = useService({
    service: getEnquiryById,
    trigger: id,
    params: id,
  });
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const store = useStore();
  const notes = store.getState().notes.notes;
  let history = useHistory();

  useEffect(() => {
    const { fetchBookingDesks, token, agencyAccount, isSuperAgent, corporateAccountBookingDesksUnload } = props;
    if (!id && isSuperAgent && agencyAccount) {
      fetchBookingDesks(agencyAccount.id, token);
    }
    return () => {
      corporateAccountBookingDesksUnload();
    };
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(getNotesForRfp(id, token));
    }
  }, [id, dispatch]);

  if (!id) {
    return <RfpTable
      isVenue={false}
      isSuperAgent={props.isSuperAgent}
      isRoleTrinityEdgeRFP={props.isRoleTrinityEdgeRFP}
      corporateBookingDesks={props.corporateBookingDesks}
      isAgent={props.isAgency}
    />;
  }

  if (loading) {
    return <Loading large />;
  }

  if (error) {
    return <Warning>There was a problem fetching data</Warning>;
  }

  let handleAddVenuesToRFP = async (values) => {
    try {
      await axios.post(
        `${window.API}/rfp/enquiry/${id}/add-venues`, {
          venues: values["true"],
          addFromAdmin: true,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.token}`,
          },
        },
      ).then((response) => {
        if (response.status === 200) {
          dispatch(reset("addVenuesToRFP"));
          window.location.reload(false);
        }
      });
    } catch (err) {
    }
  };

  return (
    <div className="py-5">
      {showCancelModal && (
        <RFPCancelModalForm
          onClose={() => {
            setShowCancelModal(false);
            window.location.reload();
          }}
          enquiry={data}
        />
      )}
      {showDeniedModal && (
          <RFPDeclineModalForm
              onClose={(isDelete) => {
                setShowDeniedModal(false);
                window.location.reload();
              }}
              enquiry={data}
              noVenues={true}
          />
      )}
      <p className="p-5 text-xl text-warning edge-rfp-download-ie-message">Sorry, your browser is out of date so this
        feature will not work. Please upgrade to a later version</p>
      <ManagingAgentBanner enquiry={data} />

      <div className="flex justify-between mb-3">
        <h1 className="mb-2">{data.brief.event_name}</h1>
        <div>
          <p className="text-l">Ref: {data.client_details.ref_id}</p>
          <RfpStatus data={data} />
        </div>

        {data.brief.enquiry_status.code === "DRAFT" ? (
          <ButtonPrimary
            Element={Link}
            to={`/proposal/edit/${data.brief.share_id}`}
          >
            Edit Brief
          </ButtonPrimary>
        ) : null}
      </div>
      {data.brief?.is_highly_confidential ? (
        <div className="bg-warning px-3 py-6 my-5 block font-bold text-white">
          <p className={"mb-0"}><i className="fas text-2lg fa-exclamation-triangle mr-3" />
            This enquiry is highly confidential and can only be viewed by users with the correct permissions.
          </p>
        </div>
      ) : null}
      {!data.brief.agent_input_required && data.enquiry_status === "DRAFT" ? (
        <div className={"w-full"}>
          <p className={"bg-primary mb-5 text-white p-4"}>Draft proposal - this is a draft only and has not yet been
            submitted. Please click Edit Brief to confirm and submit.</p>
        </div>
      ) : null}
      {!props.isAgency && data.brief.agent_input_required &&   data.brief.enquiry_status.code !== ENQUIRY_STATUS.DENIED  ? (
        <div className={"w-full"}>
          <p className={"bg-primary mb-5 text-white p-4"}>Your brief has been passed to an Agent to review and suggest
            venues - you will receive an email update shortly.</p>
        </div>
      ) : null}
      {data.is_revised && data.enquiry_status === "DRAFT" && !data.managing_trinity_user ?
        <div className={"bg-edge-blue p-2 mb-4 block font-bold px-3 py-6"}>
          <p className={"mb-0"}>Previously confirmed booking has been updated - please reconfirm below:</p>
        </div>
        : null
      }
      {data.brief.enquiry_status.code === ENQUIRY_STATUS.CANCELLED ?
        <div className="bg-warning p-4 mb-5 block font-bold text-white">
          <p className={"mb-0"}><i
            className="fas text-2lg fa-exclamation-triangle mr-3" /> {`This enquiry was cancelled at ${moment(data.brief.cancellation_date).format("DD/MM/YYYY")} by ${data.brief.cancelled_by.first_name} ${data.brief.cancelled_by.surname} for the following reason: ${data.brief.cancellation_reason}`}
          </p>
        </div>
        : null
      }

      <AuthorisationBanner data={data} />
      {data.brief.enquiry_status.code === ENQUIRY_STATUS.DENIED ?
          <RFPDenyMessage enquiry={data} /> : null
      }

      <RfpBriefSection
        brief={data.brief}
        briefPreferences={data.brief_preferences}
        clientDetails={data.client_details}
        rfpId={id}
        agencySpecificData={data.agency_specific_data}
        managingTrinityUser={data.managing_trinity_user}
        notes
      />
      {data.enquiry_venue_datas.length > 0 && (
        <RfpProposals data={data} isAgency={props.isAgency || props.isSuperAgent || props.isRoleTrinityEdgeRFP} />
      )}
      {/* removed as external agents shouldn't see this */}
      {/*{props.isAgency  && data.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED && (*/}
      {/*  <AddVenue*/}
      {/*    handleSubmit={props.handleSubmit}*/}
      {/*    handleAddVenuesToRFP={handleAddVenuesToRFP}*/}
      {/*    submitting={props.submitting}*/}
      {/*    change={props.change}*/}
      {/*    formId={'addVenuesToRFP'}*/}
      {/*  />*/}
      {/*)}*/}
      {!props.isAgency && !data.managing_trinity_user && data.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED && data.brief.enquiry_status.code !== ENQUIRY_STATUS.DENIED &&
          <div className={"flex justify-center border-t border-grey pt-8 mt-2"}>
          <ButtonPrimaryRed onClick={() => setShowCancelModal(true)}>
            {props.submitting ? <Loading inline /> : "Cancel Enquiry"}
          </ButtonPrimaryRed>
        </div>
      }
      {!props.isAgency && data.managing_trinity_user && data.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED &&  data.brief.enquiry_status.code !== ENQUIRY_STATUS.DENIED &&
          <div className="flex justify-center border-t border-grey pt-8 mt-2">
          <ButtonPrimaryRed onClick={(e) => {
            window.open(`mailto:${data.managing_trinity_user.email}?subject=Cancelling RFP ${data.client_details.ref_id}`, "_blank");
            e.preventDefault();
          }}>
            Cancel Enquiry
          </ButtonPrimaryRed>
        </div>
      }
      {/*<div className={"flex justify-center border-t border-grey pt-8 mt-2"}>*/}
      {/*  <ButtonRed onClick={() => setShowDeniedModal(true)}>*/}
      {/*    {submitting ? <Loading*/}
      {/*        inline /> : data.brief.enquiry_status.code !== ENQUIRY_STATUS.DENIED ? "Decline Enquiry" : "Edit Decline Info"}*/}
      {/*  </ButtonRed>*/}
      {/*</div>*/}

    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.corporateBookingDesks,
    isSuperAgent: isSuperAgent(state.auth),
    isAgency: isAgency(state),
    token: token(state),
    isRoleTrinityEdgeRFP: isRoleTrinityEdgeRFP(state),
    corporateAccount: corporateAccountObject(state.auth),
    agencyAccount: agencyObject(state.auth),
  };
};

const mapDispatchToProps = {
  fetchBookingDesks,
  corporateAccountBookingDesksUnload,
};

export default reduxForm({
  form: "addVenuesToRFP",
})(
  connect(mapStateToProps, mapDispatchToProps)(YourRfps),
);
