import axios from "axios";
import * as ROUTES from "../../Router/misc_routes";
import * as AGENCY_ROUTES from "../../Router/corporate_routes";
import {
  NOMINAL_CODE_EDIT_REQUEST_RECEIVED,
  NOMINAL_CODE_LIST_ERROR,
  NOMINAL_CODE_LIST_RECEIVED,
  NOMINAL_CODE_LIST_REQUEST,
  NOMINAL_CODE_LIST_UNLOAD,
} from "../reducers/types";
import handleErrors from "./utils";

export const nominalCodeRequest = () => ({
  type: NOMINAL_CODE_LIST_REQUEST,
});

export const nominalCodeReceivedRequest = (data) => ({
  type: NOMINAL_CODE_LIST_RECEIVED,
  data,
});

export const nominalCodeReceivedError = (error) => ({
  type: NOMINAL_CODE_LIST_ERROR,
  error,
});

export const unloadNominalCodes = () => ({
  type: NOMINAL_CODE_LIST_UNLOAD,
});

export const nominalCodeEditReceivedRequest = (data) => ({
  type: NOMINAL_CODE_EDIT_REQUEST_RECEIVED,
  data,
});

export const fetchNominalCodes = (token, agencyId) => {
  return (dispatch) => {
    dispatch(nominalCodeRequest());
    return axios.get(window.API + ROUTES.API.NOMINAL_CODE.LIST, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(nominalCodeReceivedRequest(response));
    })
      .catch(error => {
        dispatch(nominalCodeReceivedError(error));
        handleErrors(error);
      });
  };
};

export const fetchAgencyNominalCodes = (agencyId, token) => {
  return (dispatch) => {
    dispatch(nominalCodeRequest());
    return axios.get(window.API + AGENCY_ROUTES.API.AGENCY.NOMINAL_CODE.LIST.replace(":agencyId", agencyId), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(nominalCodeReceivedRequest(response));
    })
      .catch(error => {
        dispatch(nominalCodeReceivedError(error));
        handleErrors(error);
      });
  };
};

export const editNominalCode = (id, agencyId, token, formValues) => {
  return (dispatch) => {
    dispatch(nominalCodeRequest());
    return axios.post(window.API + AGENCY_ROUTES.API.AGENCY.NOMINAL_CODE.SINGLE.replace(":id", id).replace(":agencyId", agencyId), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        dispatch(nominalCodeEditReceivedRequest(response.data));
      })
      .catch(error => {
        dispatch(nominalCodeReceivedError(error));
        handleErrors(error);
      });
  };
};

export const createNominalCode = (agencyId, formValues, token) => {
  return (dispatch) => {
    dispatch(nominalCodeRequest());
    return axios.post(window.API + AGENCY_ROUTES.API.AGENCY.NOMINAL_CODE.LIST.replace(":agencyId", agencyId), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        dispatch(nominalCodeEditReceivedRequest(response.data));
        window.location.reload()
      })
      .catch(error => {
        dispatch(nominalCodeReceivedError(error));
        handleErrors(error);
      });
  };
};


export const deleteNominalCode = (id, agencyId, formValues, token) => {
  return (dispatch) => {
    dispatch(nominalCodeRequest());
    return axios.post(window.API + AGENCY_ROUTES.API.AGENCY.NOMINAL_CODE.SINGLE.replace(":id", id).replace(":agencyId", agencyId), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        dispatch(nominalCodeEditReceivedRequest(response.data));
        window.location.reload();
      })
      .catch(error => {
        dispatch(nominalCodeReceivedError(error));
        handleErrors(error);
      });
  };
};
