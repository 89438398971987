import React from "react";
import BriefSummary from "./BriefSummary";
import ClientDetails from "./ClientDetails";
import HotelContact from "./HotelContact";
import EnquiryFiles from "./EnquiryFiles";
import AgencySpecific from "./AgencySpecific";
import { isRoleEdgeAdmin, isRoleTrinity, isSuperAgent } from "../../modules/permissions";
import { isAgency, isVenue, token } from "../../modules/auth";
import { connect } from "react-redux";
import ManagedTrinityUser from "./ManagedTrinityUser";
import RfpAgencyNotes from "./RfpAgencyNotes";
import VenueConfirmedInformation from "./VenueConfirmedInformation";
import EditInitialResponse from "./EditInitialResponse";
import NotificationTracking from "./NotificationTracking";
import LinkedEnquiries from "./LinkedEnquiries";
import {ENQUIRY_VENUE_DATA_STATUS} from "../../modules/rfp/enquiryVenueDataStatus";

import { Field, reduxForm, change, getFormValues} from 'redux-form'
import VenueUserCarbonInfomation from "./VenueUserCarbonInfomation";

const mapStateToProps = (state) => {
  return {
    ...state.notes,
    isSuperAgent: isSuperAgent(state.auth),
    isRoleTrinity: isRoleTrinity(state.auth),
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isVenue: isVenue(state),
    isAgency: isAgency(state),
    token: token(state),
  };
};

const RfpBriefSection = ({
                           brief,
                           clientDetails = {}, // todo remove default
                           expanded,
                           contacts,
                           setShowContactForm,
                           values,
                           venueId,
                           rfpId,
                           validation,
                           form,
                           checksheet,
                           agencySpecificData = {},
                           managingTrinityUser = {},
                           isRoleTrinity,
                           isSuperAgent,
                           isRoleEdgeAdmin,
                           isVenue,
                           notes = {},
                           isAgency,
                           initialResponse,
                           venueSlug,
                           event,
                           briefPreferences,
                           enquiryVenueData,
                           token,
                         }) => {

  return (
    <>
      <div className="w-full">
        <BriefSummary
          admin
          expanded={expanded}
          {...brief}
          briefPreferences={briefPreferences}
          isVenue={isVenue}
          enquiryVenueData={enquiryVenueData}
        />
      </div>
      <div className="w-full">
        {(brief.attachments && brief.attachments.length > 0) && (
          <EnquiryFiles admin expanded={expanded} attachments={brief.attachments} />
        )}
      </div>
      <div className="xl:flex">
        {(managingTrinityUser && Object.values(managingTrinityUser).length > 0 && (isVenue || (isRoleTrinity || isSuperAgent || isRoleEdgeAdmin))) ? (
          <div className="xl:mr-5 xl:w-2/5">
            <ManagedTrinityUser {...managingTrinityUser} />
          </div>
        ) : (
          <div className="xl:mr-5 xl:w-2/5">
            {(agencySpecificData.share_with_venues && isVenue) ? (
              <ClientDetails
                {...clientDetails}
                clientName={agencySpecificData.client_name}
                original_client={agencySpecificData.original_client}
                label={"Client Details"}
              />
            ) : (
              <ClientDetails {...clientDetails} label={(isVenue || isAgency) ? "Agent Details" : "Client Details"} />
            )}
          </div>
        )}
        {(isVenue && agencySpecificData.share_with_venues > 0) && (
          <div className="xl:mr-5 xl:w-2/5">
            <AgencySpecific expanded {...agencySpecificData} label={"Client Details"} />
          </div>
        )}

        {((isSuperAgent || isRoleTrinity || isRoleEdgeAdmin) && agencySpecificData) && (
          <div className="xl:mr-5 xl:w-2/5">
            <AgencySpecific expanded {...agencySpecificData} label={"Client"} />
          </div>
        )}
        {values && (
          <div className="xl:mr-5 xl:w-2/5">
            <HotelContact
              contacts={contacts}
              setShowContactForm={setShowContactForm}
              values={values}
              venueId={venueId}
              rfpId={rfpId}
              validation={validation}
              form={form}
              checksheet={checksheet}
            />
          </div>
        )}

        {(isSuperAgent || isRoleTrinity || isRoleEdgeAdmin || isAgency || isVenue) && (
          <EditInitialResponse initialResponse={initialResponse} rfpId={rfpId} venueSlug={venueSlug} />
        )}
      </div>

      {isSuperAgent ? (
        <div className="w-full">
          <LinkedEnquiries
            expanded={false}
            rfpId={rfpId}
            label={"Linked Enquiries"}
            token={token}
          />
        </div>
      ) : null}

      <NotificationTracking
        expanded
        token={token}
        enquiryId={rfpId}
        enquiryVenueDataId={enquiryVenueData?.id}
      />

      {(isAgency || isRoleEdgeAdmin) && (
        <div className={"w-full"}>
          <RfpAgencyNotes notes={notes} rfpId={rfpId} />
        </div>
      )}
        {(isVenue && event && event.has_valid_carbon_impact) && (
            <VenueUserCarbonInfomation event={event} />

        )}
        {(isVenue && enquiryVenueData && event && event.requires_invoicing && (parseInt(event.event_status.code) >= 30 || parseInt(event.event_status.code) === 20) &&
            (enquiryVenueData.status < ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED && enquiryVenueData.status > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS )) && (
            <div className="w-full">
                <VenueConfirmedInformation event={event} />
            </div>
        )}
    </>
  );
};

export default (connect(
  mapStateToProps,
  null,
)(RfpBriefSection));
