import React from 'react';
import { ButtonSpan } from "../Button";
import classNames from 'classnames';

export const renderBookingDesks = (bookingDesks, setBookingDesk, bookingDesk, bookingDeskOpen, setBookingDeskOpen) => {

    let sectionHeader = (name, key, bookingDeskOpen) => (
        <div>
            <h2
                className={`text-2xl flex justify-between p-3 leading-none font-semibold relative`}
            >
                <span className={'flex justify-center'}>
                      {name} -
                    {allEnquiresButton(bookingDesk, setBookingDesk, setBookingDeskOpen)}
                </span>

                <ButtonSpan
                    onClick={() => {
                        setBookingDeskOpen(!bookingDeskOpen)
                    }}
                    className="outline-none my-auto ml-2"
                    id={key}
                >
                    <i className={`fal fa-${bookingDeskOpen ? "minus" : "plus"}`}/>
                </ButtonSpan>

            </h2>

        </div>


    );

    let deskOutput = (agencyName, i) => {
        if (bookingDesks[agencyName].length === 0) {
            return null;
        }
        return <div className={''}>
            {agencyName ? <div className={`px-2 pt-2 pb-1 font-bold text-xs ${i%2 ? 'bg-dark-grey' : 'bg-light-black'} text-white`} title={agencyName}>{agencyName} Desks</div> : null}
            <div
                className={`flex flex-col flex-wrap lg:flex-row p-1 lg:items-end border-b border-grey ${i%2 ? 'bg-dark-grey' : 'bg-light-black'}`}
            >
                {typeof bookingDesks[agencyName].map !== 'undefined' ? bookingDesks[agencyName].map(desk => {
                        return (
                            <div key={desk.id}>
                                {
                                    deskButton(desk, bookingDesk, setBookingDesk)
                                }
                            </div>
                        )
                    }
                ) : null}
            </div>
        </div>
    }

    let deskButton = (desk, bookingDesk, setBookingDesk) => {
        return <div
            className={classNames(
                "cursor-pointer flex-1 py-2 px-3 text-sm font-bold text-center mr-1 mb-1 lg:mb-0",
                {
                    "bg-off-white hover:bg-white": Number(bookingDesk) !== Number(desk.id),
                    "bg-white underline": Number(bookingDesk) === Number(desk.id),
                }
            )}
            onClick={() => setBookingDesk(bookingDesk !== desk.id ? desk.id : "")}
        >
            {desk.name}
        </div>
    }

    let allEnquiresButton = (bookingDesk, setBookingDesk, setBookingDeskOpen) => {
        return <ButtonSpan
            className={classNames(
                "uppercase inline-block flex items-center py-2 px-3 border border-grey text-sm font-bold ml-2",
                {
                    // "opacity-50": bookingDesk !== "",
                    "bg-brand": bookingDesk === "",
                    "bg-white": bookingDesk !== ""
                }
            )}
            onClick={() => {
                setBookingDesk("");
                setBookingDeskOpen(false)
            }}
        >
            Show all
        </ButtonSpan>
    }
    if (Object.values(bookingDesks).length > 1) {
        return (
            <div className={`bg-light-blue-grey mb-4`}>
                {sectionHeader('Booking Desks', 'bookingDesks', bookingDeskOpen)}
                {bookingDeskOpen && <div className={'flex flex-col'}>{Object.keys(bookingDesks).map((agencyName,i)=> deskOutput(agencyName,i))}</div>}
            </div>

        )
    } else {
        if(Object.entries(bookingDesks).length === 1 && Object.values(bookingDesks)[0].length === 0) {
            return <></>
        }
        if (Object.values(bookingDesks).length === 1) {
            return (<div
                className="flex flex-col flex-wrap lg:flex-row justify-around align-bottom p-3 bg-light-blue-grey border-t border-light-off-blue -mt-3 mb-3 lg:items-end">
                {allEnquiresButton(bookingDesk, setBookingDesk, setBookingDeskOpen)}
                {Object.values(bookingDesks)[0].map(desk => {
                        return (
                            <div key={desk.id} className="flex flex-col">
                                {
                                    deskButton(desk, bookingDesk, setBookingDesk)

                                }
                            </div>
                        )
                    }
                )}
            </div>)
        }
    }
}