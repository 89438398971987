import React, {Fragment} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux';
import {renderFormInput} from "../../modules/formTools";
import {required} from "../../modules/validation";
import Loading from "../Loading";
import {ButtonPrimary} from "../Button";
import {token} from "../../modules/auth";
import {sendAgencyUserInvite, sendCompanyUserInvite} from "../../redux/actions/corporateInvites";
import {Failed, Toggle} from "../Form";

const mapStateToProps = (state) => {
  return {
    token: token(state),
    initialValues: {}
  }
}

const mapDispatchToProps = {
  sendAgencyUserInvite,
  sendCompanyUserInvite
}

const v = {
  first_name: required("Please enter First Name"),
  surname: required("Please enter surname"),
  email_address: required("Please enter email")
}

const UserInviteForm = (props) => {
  const [error, setError] = React.useState(null);
  const onSubmit = formValues => {
    setError(null);
    if (props.type === 'agency') {
      return props.sendAgencyUserInvite(props.agency.id, formValues, props.token).then((response) => window.location.reload()).catch((error) =>  ( error?.response?.data ?  setError(error.response.data) : typeof Object.values(error?.errors) !== "undefined" ? setError(Object.values(error?.errors)[0]): setError('There was a problem with sending the invite')))
    } else if (props.type === 'company') {
      return props.sendCompanyUserInvite(props.company.id, formValues, props.token).then((response) => window.location.reload()).catch((error) => ( error?.response?.data ?  setError(error.response.data) : typeof Object.values(error?.errors) !== "undefined" ? setError(Object.values(error?.errors)[0]): setError('There was a problem with sending the invite')))
    }
  }
  return (
    <Fragment>
      <h3 className={'uppercase pt-5 border-t border-grey my-5'}>Invite a new user</h3>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col col-w-full md:col-w-1/3">
            <Field
                name={"first_name"}
                label={"First Name"}
                component={renderFormInput}
                validate={v.first_name}
            />
          </div>
          <div className="col col-w-full md:col-w-1/3">
            <Field
                name={"surname"}
                label={"Surname"}
                component={renderFormInput}
                validate={v.surname}
            />
          </div>
          <div className="col col-w-full md:col-w-1/3">
            <Field
                name={"email"}
                label={"Email"}
                component={renderFormInput}
                validate={v.email_address}
            />
          </div>
        </div>
        {
          props.company && props.company.corporate_account && props.company.corporate_account.is_active ?
              <div className="row">
                <div className="col col-w-full">
                  <Field
                      name="give_corporate_access"
                      label="Give Corporate EDGE Access"
                      component={Toggle}
                      wrapperClassName="mb-8 flex-grow"
                      defaultValue={1}
                  />
                </div>
              </div>
              :
              null
        }

        <div>

        </div>
        <div className="row">
          <div className="col col-w-full">
            <ButtonPrimary type="submit" classes={''}>
              {props.submitting ? <Loading inline/> : "Send"}
            </ButtonPrimary>
          </div>
        </div>
        <div className="row">
          <div className="col col-w-full">
            {error ? <Failed text={error}/> : null}
          </div>
        </div>
      </form>
    </Fragment>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'userInviteForm',
  enableReinitialize: true
})(UserInviteForm));
