import CorporateAccountOverviewContainer from "./overview/CorporateAccountOverviewContainer";
import CorporateAccountDocumentContainer from "./documents/CorporateAccountDocumentListContainer";
import CorporateAccountLocationListContainer from "./locations/CorporateAccountLocationListContainer";
import CorporateAccountFavouriteLocationsContainer from "./locations/CorporateAccountFavouriteLocationsContainer";
import EventsContainer from "./calendar-events/EventsContainer";
import CalendarView from "./calendar-events/CalendarView";
import CorporateAccountDocumentCategoryListContainer from "./documents/CorporateAccountDocumentCategoryListContainer";
import BookingDesksContainer from "./booking-desks/BookingDesksContainer";
import DepartmentsContainer from "./department/DepartmentsContainer";
import CorporateAccountDocumentCategoryAddContainer from "./documents/CorporateAccountDocumentCategoryAddContainer";

export const sectionTypes = [
  { name: "overview", component: CorporateAccountOverviewContainer },
  { name: "manage-booking-desks", component: BookingDesksContainer },
  { name: "manage-departments", component: DepartmentsContainer },
  { name: "manage-locations", component: CorporateAccountLocationListContainer },
  { name: "company-locations", component: CorporateAccountFavouriteLocationsContainer },
  { name: "setup-document-categories", component: CorporateAccountDocumentCategoryAddContainer },
  { name: "manage-documents", component: CorporateAccountDocumentContainer },
  { name: "view-documents", component: CorporateAccountDocumentCategoryListContainer },
  { name: "manage-industry-events", component: EventsContainer },
  { name: "events-calendar", component: CalendarView },
];
