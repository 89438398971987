import {
  AGENCY_BOOKING_DESK_LIST_UNLOAD,
  AGENCY_BOOKING_DESKS_LIST_RECEIVED, AGENCY_BOOKING_DESKS_LIST_RECEIVED_ERROR,
  AGENCY_BOOKING_DESKS_LIST_REQUEST,
  CORPORATE_ACCOUNT_BOOKING_DESK_CREATED,
  CORPORATE_ACCOUNT_BOOKING_DESK_DELETED,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD,
  CORPORATE_BOOKING_DESK_UPDATED,
  AGENCY_BOOKING_DESK_CREATED,
  AGENCY_BOOKING_DESK_CREATED_ERROR,
  AGENCY_BOOKING_DESK_DELETED,
} from "./types";

export const corporateBookingDesks = (state = {
  corporateBookingDesks: {},
  isFetchingBookingDesks: false,
  error: false,
  showBookingDeskForm: false,
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST:
      return {
        ...state,
        isFetchingBookingDesks: true,
      };
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED:
      return {
        ...state,
        isFetchingBookingDesks: false,
        corporateBookingDesks: action.data,
      };
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingBookingDesks: false,
      };
    case CORPORATE_BOOKING_DESK_UPDATED:
      let updatedBookingDesk = state.corporateBookingDesks.findIndex(desk => desk.id === action.data.id);
      state.corporateBookingDesks[updatedBookingDesk] = action.data
      return {
        ...state,
        isFetchingBookingDesks: false,
        corporateBookingDesks: state.corporateBookingDesks
      };
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD:
      return {
        ...state,
        corporateBookingDesks: {},
      };
    case CORPORATE_ACCOUNT_BOOKING_DESK_DELETED:
      return {
        ...state,
        isFetchingBookingDesks: false,
        corporateBookingDesks: [...state.corporateBookingDesks.filter(obj => {
          return obj.id !== action.data.data.id;
        })],
      };
    case CORPORATE_ACCOUNT_BOOKING_DESK_CREATED:
      state.corporateBookingDesks.push(action.data);
      return {
        ...state,
        isFetchingBookingDesks: false,
        corporateBookingDesks: [...state.corporateBookingDesks],
        showBookingDeskForm: false,
      };
    default:
      return state;
  }
};

export const agencyBookingDesks = (state = {
  agencyBookingDesks: {},
  isFetchingAgencyBookingDesks: false,
  error: false,
  showBookingDeskForm: false,
  agencyBookingDesksAccessDenied: false,
}, action) => {
  switch (action.type) {
    case AGENCY_BOOKING_DESKS_LIST_REQUEST:
      return {
        ...state,
        agencyBookingDesks: {},
        isFetchingAgencyBookingDesks: false,
        error: false,
        agencyBookingDesksAccessDenied: false
      };
    case AGENCY_BOOKING_DESKS_LIST_RECEIVED:
      return {
        ...state,
        agencyBookingDesks: action.data,
        isFetchingAgencyBookingDesks: false,
        agencyBookingDesksAccessDenied: false
      };
    case AGENCY_BOOKING_DESK_CREATED:
      let key = Object.keys(state.agencyBookingDesks)[0];
      state.agencyBookingDesks[key].push(action.data);
      return {
        ...state,
        isFetchingAgencyBookingDesks: false,
        agencyBookingDesks: [...state.agencyBookingDesks],
        agencyBookingDesksAccessDenied: false
      };
    case AGENCY_BOOKING_DESK_CREATED_ERROR:
      return {
        ...state,
        isFetchingAgencyBookingDesks: false,
        error: true,
      };
    case AGENCY_BOOKING_DESK_DELETED:
      return {
        ...state,
        isFetchingAgencyBookingDesks: false,
        agencyBookingDesks: [...state.agencyBookingDesks.filter(obj => {
          return obj.id !== action.data.data.id;
        })],
      };
    case AGENCY_BOOKING_DESKS_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingAgencyBookingDesks: false,
        agencyBookingDesksAccessDenied: action?.error?.response?.status === 403,
      };
    case AGENCY_BOOKING_DESK_LIST_UNLOAD:
      return {
        ...state,
        agencyBookingDesks: {},
        isFetchingAgencyBookingDesks: false,
        agencyBookingDesksAccessDenied: false
      };
    default:
      return state;
  }
};
