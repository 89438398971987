export const API = {
  VAT_RATE: {
    LIST: '/misc/vat-rate/',
    SINGLE: '/misc/vat-rate/:id',
  },
  NOMINAL_CODE: {
    LIST: '/misc/nominal-code/',
    SINGLE: '/misc/nominal-code/:id',
  },
  SITE_VISITS: {
    LIST: '/site-visits',
    SINGLE: '/site-visits/:id'
  }
}
