import React, {Component} from 'react';
import {connect} from "react-redux";
import {fetchCorporateAccounts, unloadCorporateAccountList} from "../../../redux/actions";
import {isSuperAgent, token} from "../../../modules/auth";
import CorporateAccountList from "./CorporateAccountList";
import {isCompanyAdmin} from "../../../modules/permissions";


const mapStateToProps = state => ({
    ...state.corporateAccountList,
    token: token(state),
    isCompanyAdmin: isCompanyAdmin(state.auth),
    isSuperAgent: isSuperAgent(state)
})

const mapDispatchToProps = {
    fetchCorporateAccounts,
    unloadCorporateAccountList
}

class CorporateAccountListContainer extends Component {
    componentDidMount() {
        this.props.fetchCorporateAccounts(this.props.token);
    }

    componentWillUnmount() {
        this.props.unloadCorporateAccountList();
    }

    render() {
        const {corporateAccounts, isFetching, isCompanyAdmin} = this.props;
        return <CorporateAccountList accounts={corporateAccounts} isFetching={isFetching} isCompanyAdmin={isCompanyAdmin}/>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountListContainer)