import React from "react";
import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import Error from "../Components/Form/Error";
import {array} from "prop-types";

export const objectDiff = (origObj, newObj) => {
  const changes = (newObj, origObj) => {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key]) || key === 'id') {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

export const processFormValues = (
  inProps,
  formValues,
  original,
  files = null, // Can be a single file, an array of files, or an object with file keys
  fileKey = 'logo_file'
) => {
  const formData = new FormData();

  if (inProps.dirty && Object.keys(original).length > 0) {
    formData.append("_method", "PATCH");

    if (files) {
      if (files instanceof File) {
        formData.append(fileKey, files);
      } else if (Array.isArray(files)) {
        files.forEach((file, index) => {
          formData.append(`${fileKey}[${index}]`, file);
        });
      } else if (typeof files === 'object') {
        Object.entries(files).forEach(([key, fileOrFiles]) => {
          if (Array.isArray(fileOrFiles)) {
            fileOrFiles.forEach((file, index) => {
              formData.append(`${key}[${index}]`, file);
            });
          } else if (fileOrFiles instanceof File) {
            formData.append(key, fileOrFiles);
          }
        });
      }
    }

    const data = objectDiff(original, formValues);
    Object.entries(data).forEach(([key, value]) => {
      if (value && !Array.isArray(value)) {
        formData.append(key, value);
      } else if (value && value.length > 0) {
        formData.append(key, JSON.stringify(value));
      }
    });

    return inProps.onSubmit(formData, inProps.token);
  } else {
    if (files) {
      if (files instanceof File) {
        formData.append(fileKey, files);
      } else if (Array.isArray(files)) {
        files.forEach((file, index) => {
          formData.append(`${fileKey}[${index}]`, file);
        });
      } else if (typeof files === 'object') {
        Object.entries(files).forEach(([key, fileOrFiles]) => {
          if (Array.isArray(fileOrFiles)) {
            fileOrFiles.forEach((file, index) => {
              formData.append(`${key}[${index}]`, file);
            });
          } else if (fileOrFiles instanceof File) {
            formData.append(key, fileOrFiles);
          }
        });
      }
    }

    Object.entries(formValues).forEach(([key, value]) => {
      if (value) {

        formData.append(key, value);
      }
    });

    return inProps.onSubmit(formData, inProps.token);
  }
};

//Jon -> I've quickly seperated these into two distinct methods from above because the logic to detect whether post or patch was kind of spotty to me and imo shouldnt be done automagically.
export const processFormValuesPost = (inProps, formValues, original, file, fileKey = 'logo_file') => {
  const formData = new FormData();
    if (file) {
      formData.append(fileKey, file)
    }
  Object.entries(formValues).forEach(([key, value]) => {
    if (typeof value !== "undefined" && !isArray(value)) {
      formData.append(key, value)
    } else {
      if (value && value.length > 0) {
        formData.append(key, JSON.stringify(value));
      }
    }
  });
    return inProps.onSubmit(formData, inProps.token);
}

export const processFormValuesPatch = (inProps, formValues, original, file, fileKey = 'logo_file') => {
  const formData = new FormData();
  formData.append("_method", "PATCH");
  if (file) {
    formData.set(fileKey, file)
  }
  Object.entries(formValues).forEach(([key, value]) => {

    if (value && value !== 'null') {
      if (!isArray(value)) {
        return formData.append(key, value)
      } else {

        if (value.length > 0) {
          return formData.append(key, JSON.stringify(value))
        }
      }
    }
  });
  return inProps.onSubmit(formData, inProps.token)
}

const renderError = ({error, touched, warning}) => {
  if (touched && (error || warning)) {
    return (
      <Error touched={touched} warning={warning} error={error}/>
    )
  }
}

export const renderFormInput = ({input, label, meta, className = '', labelClassName = 'text-sm', readOnly = false, disabled=false}) => {
  return (
    <div className="field mb-4">
      <label className={labelClassName + ` mb-1 block font-normal font-heading uppercase`}>{label}</label>
      <input
        {...input}
        className={className + ` px-2 py-0 border w-full outline-none font-normal text-black text-sm focus:border-primary bg-white rounded-none appearance-none border-grey-md h-10`}
        autoComplete="off"
        disabled={disabled}

      />
      {renderError(meta)}
    </div>
  )
}
