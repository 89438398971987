import React, { useCallback, useEffect, useState } from "react";
import ReportFilters from "../../../../Components/corporate/reports/ReportFilters";
import EnquiryReportTable from "../../../../Components/corporate/reports/EnquiryReportTable";
import { connect } from "react-redux";
import {
  companyUnload,
  corporateDepartmentsUnload,
  enquiryLogReportUnload,
  exportEnquiryLogReport, fetchAgenciesForSelect, fetchCompanies,
  fetchCompaniesForEvents,
  fetchCompany,
  fetchCorporateAccount,
  fetchCorporateDepartments,
  fetchEnquiryLogReport, unloadAgencyList,
  unloadCompanyList,
  unloadCorporateAccount,
} from "../../../../redux/actions";
import axios from "axios";
import { Pagination } from "../../../../ui";
import { token } from "../../../../modules/auth";
import { companyObject, corporateAccountObject, isAgencyUser, isRoleEdgeAdmin } from "../../../../modules/permissions";
import Loading from "../../../../Components/Loading";
import { removeEmptySearchParams, usePrevious } from "../../../../utils";
import { fetchVenuesFromHotelGroup } from "../../../../redux/actions/venues";
import { saveData } from "../../../../services/downloadFile";
import * as ROUTES from "../../../../Router/corporate_routes";
import { ButtonPrimary } from "../../../../Components/Button";

const EnquiryReportListContainer = (props) => {
  const {
    isAgency,
    corporateAccount,
    fetchCorporateDepartments,
    token,
    corporateDepartments = [],
    enquiryLogPage,
    enquiryLogLastPage,
    enquiryLogTotal,
    enquiryLogReport,
    enquiryLogReportUnload,
    fetchEnquiryLogReport,
    exportEnquiryLogReport,
    isFetchingEnquiryLog,
    companyObject,
    venues,
    fetchCompaniesForEvents,
    fetchVenuesFromHotelGroup,
    companies,
    fetchCorporateAccount,
    corporateDepartmentsUnload,
    unloadCompanyList,
    unloadCorporateAccount,
    fetchCompany,
    companyUnload,
    errorData,
    selectedAgencies,
    isFetchingCompany,
    isRoleEdgeAdmin,
    fetchAgenciesForSelect,
    agenciesForSelect,
    fetchCompanies
  } = props;
  const [page, setPage] = useState(enquiryLogPage);
  const prevPage = usePrevious(page);
  const [queryParams, setQueryParams] = useState({});
  const [isExporting, setIsExporting] = useState(false);
  const [company, setCompany] = useState(companyObject);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState();
  const [exportData, setExportData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    if (isRoleEdgeAdmin) {
      fetchAgenciesForSelect(token, true);
    }
  }, [])

  useEffect(() => {
    if (isRoleEdgeAdmin && selectedAgency) {
      fetchCompanies(token, null, { agencyId: selectedAgency }, true);
    }
  }, [isRoleEdgeAdmin, company, selectedAgency]);

  useEffect(() => {
    if (isRoleEdgeAdmin && !selectedAgency) {
      unloadCompanyList();
      window.localStorage.setItem("companyParams", []);
    }
  }, [isRoleEdgeAdmin, selectedAgency]);

  useEffect(() => {
    if (isAgency) {
      fetchCompaniesForEvents(token);
    }
    return () => {
      enquiryLogReportUnload();
      corporateDepartmentsUnload();
      unloadCompanyList();
    };
  }, [selectedAgencies]);

  useEffect(() => {
    if (corporateAccount && corporateAccount.id) {
      fetchCorporateDepartments(corporateAccount.id, token);
    }
  }, [corporateAccount]);

  useEffect(() => {
    setCompanyUsers(companyObject ? companyObject.users : []);
    setCompany(companyObject);
  }, [companyObject]);

  const runEnquiryLogReport = useCallback(
    (params, currentPage) => {
      setHasSearched(true);
      const reportParams = { ...params, page: currentPage };
      if (params.company) {
        reportParams.companies = params.company;
      }
      delete reportParams.company;
      setQueryParams(reportParams);
      fetchEnquiryLogReport(token, currentPage, reportParams);
    },
    [company, token, fetchEnquiryLogReport],
  );

  const getCorporateAccount = corporateAccountId => {
    if (corporateAccountId) {
      fetchCorporateAccount(corporateAccountId, token);
      fetchCorporateDepartments(corporateAccountId, token);
    } else {
      unloadCorporateAccount();
      corporateDepartmentsUnload();
      enquiryLogReportUnload();
    }
  };

  const runEnquiryLogReportExport = async queryParams => {
    setIsExporting(true);
    try {
      if (queryParams.page) {
        delete queryParams.page;
      }
      queryParams = new URLSearchParams(queryParams);
      removeEmptySearchParams(queryParams);
      let queryString = queryParams.toString();
      let response = await axios.get(
        window.API + ROUTES.API.CORPORATE.REPORTS.ENQUIRY_LOG_EXPORT.replace(":slug", corporateAccount.id) + "?" + queryString,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        "csv",
        response.data,
        `enquiry-log-report.csv`,
      );
      setIsExporting(false);
    } catch (e) {
      setIsExporting(false);
      console.error(e);
    }
  };

  const resetReport = () => {
    setSelectedAgency("");
    setHasSearched(false);
    enquiryLogReportUnload();
    if (isAgency) {
      corporateDepartmentsUnload();
    }
    unloadCorporateAccount();
  };

  const handleSetPage = useCallback(
    (newPage) => {
      if (page !== newPage) {
        setPage(newPage);
        runEnquiryLogReport(queryParams, newPage);
      }
    },
    [page, queryParams],
  );

  const getVenuesForHotelGroup = group => {
    return fetchVenuesFromHotelGroup(group, token);
  };

  const getCompany = companyId => {
    companyUnload();
    return fetchCompany(companyId, token);
  };

  return (
    <div className="min-w-full">
      <h1 className="my-4">
        Enquiry Log
      </h1>
      <ReportFilters
        corporateAccount={corporateAccount}
        runReport={runEnquiryLogReport}
        runReportExport={runEnquiryLogReportExport}
        exportData={exportData}
        resetReport={resetReport}
        getCompany={getCompany}
        companyUsers={companyUsers}
        companies={companies}
        total={enquiryLogTotal}
        corporateDepartments={corporateDepartments}
        getVenuesForHotelGroup={getVenuesForHotelGroup}
        venues={venues}
        getCorporateAccount={getCorporateAccount}
        showFilters={{ hotelGroup: false, users: false }}
        error={errorData && errorData.errors}
        isAgent={isAgency}
        isMultiFilters={true}
        isEdgeAdmin={isRoleEdgeAdmin}
        shouldDisableSubmit={isFetchingEnquiryLog || isFetchingCompany}
        agenciesForSelect={agenciesForSelect}
        selectedAgency={selectedAgency}
        setSelectedAgency={setSelectedAgency}
      />

      {!isFetchingEnquiryLog && hasSearched && enquiryLogReport && enquiryLogReport.length === 0 ?
        <div className="p-3 bg-warning text-white w-full my-6">
          <p className={"mb-0"}><i className="fas text-2lg fa-info-circle mr-3" />
            There no results found, please amend the filters above and run the report again.
          </p>
        </div>
      : null}

      {isFetchingEnquiryLog ? (
        <Loading />
      ) : enquiryLogReport && enquiryLogReport.length > 0 ? (
        <>
          <EnquiryReportTable data={enquiryLogReport} corporateAccount={corporateAccount} />
          <Pagination
            current={enquiryLogPage}
            last={enquiryLogLastPage}
            onClick={handleSetPage}
          />
          <div className="mb-2">
            <ButtonPrimary
              disabled={isExporting}
              classes="mb-0"
              onClick={() => runEnquiryLogReportExport(queryParams)}
            >
              {isExporting ? <Loading /> : "Export to CSV"}
            </ButtonPrimary>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => {
  let corporateAccount = Object.entries(corporateAccountObject(state.auth)).length > 0 ? corporateAccountObject(state.auth) : state.corporateAccount?.corporateAccount;
  let companyAccount = Object.entries(companyObject(state.auth)).length > 0 ? companyObject(state.auth) : state.company?.company;
  let companies = state.companyList.companiesForEvents ? state.companyList.companiesForEvents.data : [];
  let isEdgeAdmin = isRoleEdgeAdmin(state.auth);
  if (isEdgeAdmin) {
    companies = state.companyList.companies ? state.companyList.companies : [];
  }
  return {
    ...state.selectedAgencies,
    ...state.corporateDepartments,
    ...state.enquiryLogReport,
    ...state.isFetchingEnquiryLog,
    ...state.enquiryLogLastPage,
    ...state.enquiryLogTotal,
    ...state.enquiryLogPage,
    ...state.errorData,
    ...state.venues,
    ...state.company,
    ...state.agenciesForSelect,
    ...state.agencyList,
    companies: companies || [],
    corporateAccount: corporateAccount,
    isAgency: isAgencyUser(state.auth),
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    companyObject: companyAccount,
    token: token(state),
  };
};

const mapDispatchToProps = {
  fetchCompanies,
  fetchAgenciesForSelect,
  fetchCorporateDepartments,
  corporateDepartmentsUnload,
  fetchEnquiryLogReport,
  enquiryLogReportUnload,
  exportEnquiryLogReport,
  fetchVenuesFromHotelGroup,
  fetchCompaniesForEvents,
  fetchCorporateAccount,
  unloadCompanyList,
  unloadCorporateAccount,
  fetchCompany,
  companyUnload,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryReportListContainer);
