export const ManageFadeOut = (stateItem, setState, resetForm = false, redirect = false, history = null, location = null) => {
    let stateObj  = {}
    stateObj[stateItem] = true
    setState(stateObj)
    setTimeout(function(){
        stateObj[stateItem] = false
        setState(stateObj);
        if(resetForm)
        {
            resetForm();
        }
        if(redirect)
        {
           //redirectFunction();
        }
    }.bind(this),1500);  // wait 5 seconds, then reset to false

}

/**
 * Same as above but for functional based components.
 * e.g.
 * const [state, setState] = useState({
 *   categorySuccess: false,
 *   error: false,
 * });
 * FunctionalManageFadeOut("categorySuccess", setState);
 */
export const FunctionalManageFadeOut = (stateItem, setState, timeout = 1500, resetForm = null) => {
  let stateObj = {};
  stateObj[stateItem] = true;
  setState(prevState => ({ ...prevState, ...stateObj }));
  setTimeout(() => {
    stateObj[stateItem] = false;
    setState(prevState => ({ ...prevState, ...stateObj }));
    if (resetForm) {
      resetForm();
    }
  }, timeout);
};


export const ManageFadeOutRedirection = (stateItem, setState, resetForm = false, history = null, location = null, timeout = 1500) => {
    let stateObj  = {}
    stateObj[stateItem] = true
    setState(stateObj)
    setTimeout(function(){
        stateObj[stateItem] = false
        setState(stateObj);
        if(resetForm)
        {
            resetForm();
        }
        history.push(location)
    }.bind(this), timeout);  // wait 5 seconds, then reset to false

}

