import React, { useEffect } from "react";
import CorporateBookingDeskForm from "../../../../Components/corporate/CorporateBookingDeskForm";
import { connect } from "react-redux";
import { token } from "../../../../modules/auth";
import { editCorporateBookingDesk, fetchCorporateBookingDesks } from "../../../../redux/actions";
import Loading from "../../../../Components/Loading";

const BookingDesksContainer = ({
                                 corporateBookingDesks,
                                 token,
                                 match,
                                 fetchCorporateBookingDesks,
                                 editCorporateBookingDesk,
                                 isFetchingBookingDesks,
                               }) => {
  const { slug } = match?.params || {};

  useEffect(() => {
    fetchCorporateBookingDesks(slug, token);
  }, []);

  const onSubmitBookingDesks = formValues => {
    const bookingDeskId = formValues.get("id");
    return editCorporateBookingDesk(slug, bookingDeskId, formValues, token);
  };

  return (
    <div className={"bg-white p-5 mt-10"}>
      <div>
        <h3 className={"mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
          Booking Desks
        </h3>
        <div className="mb-4 text-xs border border-grey p-2">
          <p>
            Legal Text replaces the default content displayed on the Confirmation PDF. Please use variables below
            in the same format for Legal Text (valid HTML allowed):
          </p>
          <ul className="list-reset font-bold">
            <li>:clientVar</li>
            <li>:venueVar</li>
            <li>:companyVar</li>
          </ul>
        </div>
        {isFetchingBookingDesks ? <Loading /> : (
          corporateBookingDesks && corporateBookingDesks.length > 0 ? (
            <>
              {corporateBookingDesks.map(bookingDesk => {
                return <CorporateBookingDeskForm
                  key={bookingDesk.id}
                  onSubmit={onSubmitBookingDesks}
                  bookingDesk={bookingDesk}
                  form={`bookingDeskForm-${bookingDesk.id}`}
                />;
              })}
            </>
          ) : <>
            <p>No booking desks available, please save them from overview.</p>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.corporateBookingDesks,
    token: token(state),
  };
};

const mapDispatchToProps = {
  fetchCorporateBookingDesks,
  editCorporateBookingDesk,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDesksContainer);
