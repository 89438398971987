import React, {useEffect, useState} from "react";
import {Field, reduxForm, getFormValues, autofill,} from "redux-form";
import {Select} from "Components/Form";
import {Input, Radio, Toggle, Panel} from "ui";
import {ButtonBrand, ButtonPrimary, ButtonSpan} from "Components/Button";
import Modal from "Components/Modal";
import {connect, useDispatch, useSelector} from 'react-redux';
import useService from "../../../../../hooks/useService";
import {getEnquiryById} from "../../../../../services/rfpService";
import {
    createCommissionFormItemFromCommission,
} from "../helpers/proposalDataGatherer";
import {
    calculateCommissionAs,
    calculateCommissionForCalculator,
    calculateTotalAs
} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import { storeFinalCommissionValues } from "../../../../../redux/actions";
import {token as tokenSelector} from "../../../../../modules/auth";
import {
    buildInitialConferencePackageItems,
    createConferencePackageCommissionItems, handleConferencePackageFieldUpdate
} from "../helpers/conferencePackageHandler";
import {
    buildInitialMeetingRoomItems,
    createMeetingRoomCommissionItems,
    handleMeetingRoomFieldUpdate
} from "../helpers/meetingRoomHandler";
import {
    buildInitialEquipmentItems,
    createEquipmentCommissionItems,
    handleEquipmentFieldUpdate
} from "../helpers/equipmentHandler";
import {
    buildInitialAdditionalRequirementItems, createAdditionalRequirementsCommissionItems,
    handleAdditionalRequirementFieldUpdate
} from "../helpers/additionalRequirementsHandler";
import {
    buildInitialAccommodationItems,
    createAccommodationCommissionItems,
    handleAccommodationFieldUpdate
} from "../helpers/accommodationHandler";
import {buildInitialCustomItems, handleCustomFieldUpdate} from "../helpers/customHelper";
import {required} from "../../../../../modules/validation";
import AddNewLine from "./ModalComponents/AddNewLine";
import ReloadFromConfirmation from "./ModalComponents/ReloadFromConfirmation";
import filterFormValues from "./Helpers/filterFormValues";
import ReactTooltip from 'react-tooltip';
import Loading from "../../../../../Components/Loading";
import CurrencySelector from "../../../rfp/Checksheet/CurrencySelector";
import {getCurrencySymbol, htmlCodeToUnicode, parseAndRound} from "../../../../../utils";
import {
    buildInitialAdditionalCostingItems,
    createAdditionalCostingsCommissionItems, handleAdditionalCostingItemsFieldUpdate
} from "../helpers/additionalCostingsHelper";
import FinancialsComponent  from "./FinancialsComponent";

export const LINE_ITEM = 1;
export const PRICE_NET = 2;
export const PRICE_VAT = 5;
export const PRICE_TOTAL = 6;
export const PER_PERSON_VAT = 8;
export const VAT = 4;
export const COMMISSION = 7;
export const APPLIED_ON = 3;
export const COMMISSION_VALUE = 9;


const mapStateToProps = (state, props) => {
    let enquiryVenueData = props.enquiry.enquiry_venue_datas.find(evd => evd.id === props.event.enquiry_venue_datas.id);
    let formValues = {};
    let currency =  props.event?.currency_id ? props.event.currency : ! props.event.total_commission_value_net ? props.event?.enquiry_venue_datas?.currency : false;
    if (!props.isResetting && props.event.event_commission_items && props.event.event_commission_items.length > 0) {
        props.event.event_commission_items.forEach((commissionItem) => {
            let hasSrcCurVals = currency && commissionItem.commission_value_net_src_cur;
            let commissionValue = Number(parseFloat((currency && hasSrcCurVals ? commissionItem.commission_value_net_src_cur: commissionItem.commission_value_net)).toFixed(2)) + Number(parseFloat((currency && hasSrcCurVals ? commissionItem.commission_value_vat_src_cur: commissionItem.commission_value_vat)).toFixed(2));
            const itemPrice = commissionItem.commission_applied_on == "Gross" ? Number(parseFloat((currency && hasSrcCurVals ? commissionItem.price_net_src_cur: commissionItem.price_net)).toFixed(2))+ Number(parseFloat((currency && hasSrcCurVals ? commissionItem.price_vat_src_cur: commissionItem.price_vat)).toFixed(2)) : parseFloat((currency && hasSrcCurVals ? commissionItem.price_net_src_cur: commissionItem.price_net)).toFixed(2);
            const total = calculateTotalAs(commissionItem.commission_applied_on, props.vatRates, commissionItem.vat_rate_id, itemPrice);
            const comm = calculateCommissionAs(commissionItem.price_includes_vat, props.vatRates, props.includesVatRate, props.includesVat, parseFloat(commissionValue).toFixed(2));
            createCommissionFormItemFromCommission(
                commissionItem,
                formValues,
                total,
                comm,
                props.includesVat,
                currency
            );
        });
    } else {
        createConferencePackageCommissionItems(enquiryVenueData, formValues, props.vatRates, props.includesVatRate, props.includesVat, currency);
        createMeetingRoomCommissionItems(enquiryVenueData, formValues, props.vatRates, props.includesVatRate, props.includesVat,  currency);
        createEquipmentCommissionItems(enquiryVenueData, formValues, props.vatRates, props.includesVatRate, props.includesVat, currency);
        createAdditionalRequirementsCommissionItems(enquiryVenueData, formValues, props.vatRates, props.includesVatRate, props.includesVat,currency);
        createAccommodationCommissionItems(enquiryVenueData, formValues, props.vatRates, props.includesVatRate, props.includesVat, currency);
        createAdditionalCostingsCommissionItems(enquiryVenueData, formValues, props.vatRates, props.includesVatRate, props.includesVat, currency);

    }
    return {
        initialValues: formValues
    };
};

const mapDispatchToProps = {
    storeFinalCommissionValues
};

const validation = {
    required: required(),
};

const Wrapper = ({children, onClose}) => (
    <Modal>
        <div className="modal">
            <div
                className="modal-inner"
                style={{
                    width: 1400,
                    maxWidth: "90%",
                }}
            >
                <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
                    <i className="fas fa-times-circle fa-2x ml-3"/>
                </ButtonSpan>
                {children}
            </div>
        </div>
    </Modal>
);


let FinalCommissionValuesModal = ({
                                      handleSubmit,
                                      event,
                                      onClose,
                                      change,
                                      storeFinalCommissionValues,
                                      vatRates,
                                      includesVat,
                                      includesVatRate,
                                  }) => {
    const dispatch = useDispatch()
    const [commissionItems, setCommissionItems] = useState([]);
    const [finalCommissionValue, setFinalCommissionValue] = useState(0);
    const [finalCommissionValueNet, setFinalCommissionValueNet] = useState(0);
    const [finalCommissionVatValue, setFinalCommissionVatValue] = useState(0);
    const [invoiceNet, setInvoiceNet] = useState(0);
    const [invoiceVat, setInvoiceVat] = useState(0);
    const [currencyCode, setCurrencyCode] = useState(event?.currency?.currency_code ?  event.currency.currency_code : 'GBP')
    const [currencyChangeLoad, setcurrencyChangeLoad] = useState(false)
    const values = useSelector(getFormValues('finalCommissionValues')) || {};
    const eventsFormValues = useSelector(getFormValues('eventsForm')) || {};
    let rfpCurrencyId = event?.currency_id ? event.currency_id : !event.total_commission_value_net ? event?.enquiry_venue_datas?.currency_id : null;
    const token = useSelector(tokenSelector);
    let vat = vatRates.find((vatRate) => {
        return Number(vatRate.id) == Number(includesVatRate)
    })?.rate
    const {loading, data, enquiryError} = useService({
        service: getEnquiryById,
        trigger: event.enquiry.id,
        params: event.enquiry.id,
    });

    useEffect(() => {
        calculateFinalCommissionValue();
        calculateFinalInvoiceValue();
    }, [values]);


    useEffect(() => {
        setUpCommissionItems();
    }, [data]);

    useEffect(() => {
        if(currencyCode === null && !event.total_commission_value_net) {
            setCurrencyCode( rfpCurrencyId ? htmlCodeToUnicode(rfpCurrencyId) : 'GBP');
        }
    }, [currencyCode]);

    useEffect(() => {
        setFinalCommissionVatValue(Number(finalCommissionValue - finalCommissionValueNet).toFixed(2));
    }, [finalCommissionValueNet, finalCommissionValue]);

    let setUpCommissionItems = (isReset = false) => {
        if (data && event.event_commission_items.length === 0) {
            let enquiryVenueData = data.enquiry_venue_datas.find(evd => evd.id === event.enquiry_venue_datas.id);
            let initialCommissionItems = [];
            buildInitialConferencePackageItems(enquiryVenueData, initialCommissionItems)
            buildInitialMeetingRoomItems(enquiryVenueData, initialCommissionItems)
            buildInitialEquipmentItems(enquiryVenueData, initialCommissionItems)
            buildInitialAdditionalRequirementItems(enquiryVenueData, initialCommissionItems)
            buildInitialAccommodationItems(enquiryVenueData, initialCommissionItems)
            buildInitialAdditionalCostingItems(enquiryVenueData, initialCommissionItems)
            if (!isReset) {
                buildInitialCustomItems(event, initialCommissionItems)
            }
            setCommissionItems(initialCommissionItems)
        }
        if (data && event.event_commission_items.length !== 0) {
            let enquiryVenueData = data.enquiry_venue_datas.find(evd => evd.id === event.enquiry_venue_datas.id);
            let initialCommissionItems = [];
            buildInitialConferencePackageItems(enquiryVenueData, initialCommissionItems)

            buildInitialMeetingRoomItems(enquiryVenueData, initialCommissionItems)
            buildInitialEquipmentItems(enquiryVenueData, initialCommissionItems)
            buildInitialAdditionalRequirementItems(enquiryVenueData, initialCommissionItems)
            buildInitialAccommodationItems(enquiryVenueData, initialCommissionItems)
            buildInitialAdditionalCostingItems(enquiryVenueData, initialCommissionItems)
            if (!isReset) {
                buildInitialCustomItems(event, initialCommissionItems)
            }
            setCommissionItems(initialCommissionItems)
        }
    }


    let removeLine = (commissionItem) => {
        let fieldKeys = [
            'line_item_',
            'type_',
            'id_',
            'price_includes_vat_',
            'value_',
            'value_netval_',
            'value_vat_',
            'final_value_',
            'vat_',
            'commission_',
            'applied_on_',
            'commission_value_',
            'commission_value_vat_',
        ]
        fieldKeys.forEach(key => {
            dispatch(autofill('finalCommissionValues', key + commissionItem.type + '_' + commissionItem.id, undefined))
        })
        setCommissionItems([...commissionItems.filter((ci) => ci.id !== commissionItem.id)]);
    }

    let fetchValue = (identifier, fieldName, filteredValues, key, value, defaultValue) => {
        if (key + identifier === fieldName) {
            return value;
        }
        if (typeof filteredValues[key + identifier] !== 'undefined' && filteredValues[key + identifier] > 0) {
            return filteredValues[key + identifier]
        }
        return defaultValue
    }

    let calculateFinalCommissionValue = () => {
        let commissionValues = Object.keys(values).filter(i => i.includes("commission_value") && !i.includes("value_net") && !i.includes("value_vat")).reduce((cur, key) => {
            return Object.assign(cur, {[key]: !isNaN(parseFloat(values[key])) && !key.includes("include") ? parseFloat(values[key]).toFixed(2) : values[key]});
        }, {});
        let finalValue = Object.values(commissionValues).reduce((partialSum, commissionValue) => {
            return Number(partialSum) + (!isNaN(Number(commissionValue)) ? Number(commissionValue) : 0);
        }, 0);
        Object.keys(commissionValues).forEach(key => {
            if (key.includes("vat")) {
                delete commissionValues[key];
            }
        });
        setFinalCommissionValue(Number(finalValue).toFixed(2));
        const totalVals = Object.keys(values).filter((i) => {
            return i.includes("commission_value_") && !i.includes("vat");
        });
        totalVals.forEach(valKey => {
            if (!valKey.includes("value_net_")) {
                values[valKey.replace("value_", "value_net_")] = values[valKey] - values[valKey.replace("value_", "value_vat_")];
            }
        });
        let commissionValuesNet = filterFormValues("value_net_", values);
        Object.keys(commissionValuesNet).forEach(key => {
            if (key.includes("vat")) {
                delete commissionValues[key];
            }
        });
        let finalValueNet = Object.values(commissionValuesNet).reduce((partialSum, commissionValue) => {
            return Number(partialSum) + (!isNaN(Number(commissionValue)) ? Number(commissionValue) : 0);
        }, 0);
        setFinalCommissionValueNet(Number(finalValueNet).toFixed(2));
    };

    let calculateFinalInvoiceValue = () => {
        let valueNets = Object.keys(values).filter(i => i.includes("value") && !i.includes("value_net") && !i.includes("value_vat") && !i.includes("commission") && !i.includes("final")).reduce((cur, key) => {
            const appliedOnKey = key.replace('value_', 'applied_on_');
            const vatKey = key.replace('value_', 'value_vat_');
            const appliedOnGross = values[appliedOnKey] == "Gross";
            let netVal = !isNaN(parseFloat(values[key])) && !key.includes("include") ? parseFloat(values[key]).toFixed(2) : values[key];
            let vatVal = !isNaN(parseFloat(values[vatKey])) && !key.includes("include") ? parseFloat(values[vatKey]).toFixed(2) : values[vatKey];
            if (appliedOnGross) {
                netVal = netVal - vatVal;
            }
            return Object.assign(cur, {[key]: netVal});
        }, {});
        let totalInvoiceNet = Object.values(valueNets).reduce((partialSum, commissionValue) => {
            return Number(partialSum) + (!isNaN(Number(commissionValue)) ? Number(commissionValue) : 0);
        }, 0);
        let valueVats = Object.keys(values).filter(i => i.includes("value_vat") && !i.includes("value_net") && !i.includes("commission") && !i.includes("final")).reduce((cur, key) => {
            return Object.assign(cur, {[key]: !isNaN(parseFloat(values[key])) && !key.includes("include") ? parseFloat(values[key]).toFixed(2) : values[key]});
        }, {});
        let totalInvoiceVat = Object.values(valueVats).reduce((partialSum, commissionValue) => {
            return Number(partialSum) + (!isNaN(Number(commissionValue)) ? Number(commissionValue) : 0);
        }, 0);
        setInvoiceNet(totalInvoiceNet);
        setInvoiceVat(totalInvoiceVat);
    };

    let updateFieldBasedOnKey = (fieldName, identifier, id, value, item_price_net, vatRate = 41) => {
        let filteredValues = filterFormValues(identifier, values);
        let netValue = fetchValue(identifier, fieldName, filteredValues, 'value_', value, item_price_net);
        let vatRateFromCalc = 20;
        let priceIncludesVat = 'price_includes_vat_' + identifier === fieldName ? value : filteredValues['price_includes_vat_' + identifier];

        if (vatRates && vatRates.length > 0) {
            vatRateFromCalc = vatRates.find((vatRate) => {
                return Number(vatRate.id) == Number(fetchValue(identifier, fieldName, filteredValues, 'vat_', value, vatRate))
            })?.rate
        }
        let commissionValue = parseFloat(calculateCommissionForCalculator(
            false,
            netValue,
            eventsFormValues ? eventsFormValues.total_commission_vat_rate_id : vatRateFromCalc,
            'commission_' + identifier === fieldName ? value : filteredValues['commission_' + identifier],
            'applied_on_' + identifier === fieldName ? value : filteredValues['applied_on_' + identifier],
        )).toFixed(2)
        let commissionTotals = calculateCommissionAs(priceIncludesVat, vatRates, includesVatRate, includesVat, commissionValue, true)
        change('commission_value_' + identifier, commissionTotals.total === 'NaN' ? 0 : commissionTotals.total)
        change('commission_value_vat_' + identifier, commissionTotals.vat)
    }


    let onPriceIs = (key, grossOrNet) => {
        let totals = calculateTotalAs(grossOrNet, vatRates, values[`vat_${key}`], values[`value_${key}`])
        change('value_netval_' + key, Number(totals.total - totals.vat).toFixed(2))
        change('value_vat_' + key, totals.vat)
        change('final_value_' + key, totals.total)
        updateCommissionValues(`final_value_${key}`, totals.total)
    }

    let updateTotals = (key, fieldEvent) => {
        let appliedOn = values[`applied_on_${key}`]
        let totals = calculateTotalAs(appliedOn, vatRates, values[`vat_${key}`], fieldEvent.target.value)
        change('value_netval_' + key, Number(totals.total - totals.vat).toFixed(2))
        change('value_vat_' + key, totals.vat)
        change('final_value_' + key, totals.total)
        updateCommissionValues(`value_${key}`, fieldEvent.target.value)
    }

    let onVat = (key, fieldEvent) => {
        let appliedOn = values[`applied_on_${key}`]
        let value = values[`value_${key}`]
        let totals = calculateTotalAs(appliedOn, vatRates, fieldEvent.target.value, value)
        change('value_netval_' + key, Number(totals.total - totals.vat).toFixed(2))
        change('value_vat_' + key, totals.vat)
        change('final_value_' + key, totals.total)
        updateCommissionValues(`final_value_${key}`, totals.total)
    }

    let updateCommissionValues = (fieldEvent, value) => {
        if (data) {
            let editedItem = fieldEvent?.target?.name ? fieldEvent.target.name.split('_') : fieldEvent.split('_');
            let enquiryVenueData = data.enquiry_venue_datas.find(evd => evd.id === event.enquiry_venue_datas.id);
            let id = fieldEvent?.target?.name ? fieldEvent.target.name.match(/\d+/)[0] : fieldEvent.match(/\d+/)[0];
            handleConferencePackageFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
            handleMeetingRoomFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
            handleEquipmentFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
            handleAdditionalRequirementFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
            handleAccommodationFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
            handleAdditionalCostingItemsFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
            handleCustomFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
        }
    }

    let submit = (values) => {
        values["final_commission_value"] = finalCommissionValue;
        values["final_commission_value_net"] = finalCommissionValueNet;
        values["final_commission_value_vat"] = finalCommissionVatValue;
        values["invoice_amount_net"] = invoiceNet;
        values["invoice_amount_vat"] = invoiceVat;
        values["total_commission_vat_rate_id"] =  eventsFormValues['total_commission_vat_rate_id'];
        values["total_commission_includes_vat"] =  eventsFormValues['total_commission_includes_vat'];
        values["total_commission_xero_code"] =  eventsFormValues['total_commission_xero_code'];
        values["estimated_commission_value_net"] = eventsFormValues["estimated_commission_value_net"];
        if (values['exchange_rate']) {
            values["final_commission_value_src_cur"] = finalCommissionValue;
            values["final_commission_value_net_src_cur"] = finalCommissionValueNet;
            values["final_commission_value_vat_src_cur"] = finalCommissionVatValue;
            values["invoice_amount_net_src_cur"] = invoiceNet;
            values["invoice_amount_vat_src_cur"] = invoiceVat;
            values["estimated_commission_value_net_src_cur"] = typeof eventsFormValues['estimated_commission_value_net_src_cur'] !== 'undefined' && eventsFormValues['estimated_commission_value_net_src_cur'] ? eventsFormValues['estimated_commission_value_net_src_cur'] : eventsFormValues['estimated_commission_value_net'];
            values["estimated_commission_value_net"] =  typeof eventsFormValues['estimated_commission_value_net_src_cur'] !== 'undefined'  && eventsFormValues['estimated_commission_value_net_src_cur'] ?  eventsFormValues["estimated_commission_value_net"] : parseAndRound(eventsFormValues['estimated_commission_value_net'], values['exchange_rate']);
            values["final_commission_value"] =  parseAndRound(finalCommissionValue, values['exchange_rate']);
            values["final_commission_value_net"] =  parseAndRound(finalCommissionValueNet, values['exchange_rate']);
            values["final_commission_value_vat"] =  parseAndRound(finalCommissionVatValue, values['exchange_rate']);
            values["invoice_amount_net"] = parseAndRound(invoiceNet, values['exchange_rate']);
            values["invoice_amount_vat"] =  parseAndRound(invoiceVat, values['exchange_rate']);
            let monetaryValues = Object.keys(values).filter(i => (i.includes("commission_value") || i.includes("value_")) && !i.includes("final") && !i.includes("estimated") ).reduce((cur, key) => {
                return Object.assign(cur, {[key]: !isNaN(parseFloat(values[key])) && !key.includes("include") ? parseFloat(values[key]).toFixed(2) : values[key]});
            }, {});
            Object.keys(monetaryValues).forEach(key => {
                values[key + '_src_cur'] = values[key];
                values[key] = parseAndRound(values[key], values['exchange_rate']);
            });
        }
        storeFinalCommissionValues(event.id, token, values);
    };

    let onCurrencyChange = (cc) => {
       setcurrencyChangeLoad(true);
       let lineItems =  filterFormValues("line_item_", values);
        Object.keys(lineItems).forEach(key => {
            let escapedCurrencyCode = getCurrencySymbol({currency_code: currencyCode}).replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            let regex = new RegExp(escapedCurrencyCode,"gi");
            if(typeof cc !== 'undefined') {
                let updatedItemLine = values[key].replace(regex, function (x) {
                 if(x) {
                     return getCurrencySymbol({currency_code: cc})
                 }else{
                     return ''
                 }
                });
                change(key,  updatedItemLine);
                setCurrencyCode(cc)

            }else{
                let updatedItemLine = values[key].replace(regex, '£');
                change(key,  updatedItemLine);
                setCurrencyCode('GBP')
                setUpCommissionItems();
            }

        });

        setcurrencyChangeLoad(false);


    }

    return (
        <Wrapper onClose={onClose}>
            <form className="relative pb-16" onSubmit={handleSubmit(submit)}>
                <div className={'flex justify-between'}>
                    <h1 className={'mb-5'}>Final Commission Values</h1>
                    {currencyCode &&
                    <div className={'flex mr-10'}>
                        {currencyChangeLoad ?   <Loading /> :   <CurrencySelector change={change} rfpCurrencyId={rfpCurrencyId} values={values} onCurrencyChange={onCurrencyChange}/>}

                    </div>}
                </div>

                <Panel additionalClasses={'border border-black '}>
                    <table className="table-auto">
                        <thead>
                        <tr className={'border-b border-black text-left'}>
                            <th><h4 className="pb-1 mb-3">Line Item</h4></th>
                            <th><h4 className="pb-1 mb-3">Price ({currencyCode ? getCurrencySymbol({currency_code: currencyCode}) : '£'})</h4></th>
                            <th><h4 className="pb-1 mb-3">Price Is</h4></th>
                            <th><h4 className="pb-1 mb-3">{currencyCode ? 'Tax' : 'VAT'} Rate</h4></th>
                            <th><h4 className="pb-1 mb-3">{currencyCode ? 'Tax' : 'VAT'} ({getCurrencySymbol({currency_code: currencyCode})})</h4></th>
                            <th><h4 className="pb-1 mb-3">Total ({currencyCode ? getCurrencySymbol({currency_code: currencyCode}) : '£'})</h4></th>
                            <th><h4 className="pb-1 pl-5 mb-3">Commission (%)</h4></th>
                            <th><h4 className="pb-1 mb-3">Inc {currencyCode ? 'Tax' : 'VAT'}{includesVat ? `(${vat}%)?` : '?'}</h4></th>
                            <th><h4 className="pb-1 mb-3">Value ({currencyCode ? getCurrencySymbol({currency_code: currencyCode}) : '£'})</h4></th>
                            <th><h4 className="pb-1 mb-3 text-right">Remove</h4></th>

                        </tr>
                        </thead>
                        <tbody>
                        {!commissionItems?.length || commissionItems.length === 0 ? <tr><td colSpan={10} className={'text-center'}><Loading /></td></tr>
                        :null}
                        {commissionItems.map(commissionItem => {
                            let key = commissionItem.type + '_' + commissionItem.id;
                            if (commissionItem.type !== 'custom') {
                                if (typeof values[`id_${key}`] == 'undefined') {
                                    return;
                                }
                                ;
                            }
                            return (
                                <tr key={key}>
                                    <td>
                                        <>
                                            <Field
                                                name={`type_${key}`}
                                                component={Input}
                                                hidden={true}
                                                wrapperClassName={'hidden text-sm'}
                                            />
                                            <Field
                                                name={`id_${key}`}
                                                component={Input}
                                                hidden={true}

                                                wrapperClassName={'hidden'}
                                            />
                                            {/*<Field*/}
                                            {/*    name={`value_netval_${key}`}*/}
                                            {/*    component={Input}*/}
                                            {/*    hidden={true}*/}

                                            {/*    wrapperClassName={'hidden'}*/}
                                            {/*/>*/}
                                        </>
                                        {commissionItem.disabled && commissionItem.disabled.includes(LINE_ITEM) ? null :
                                            <Field
                                                name={`line_item_${key}`}
                                                component={Input}
                                                size="md"

                                                validate={validation.required}
                                            />
                                        }

                                    </td>
                                    {commissionItem.disabled && commissionItem.disabled.includes(PRICE_NET) ? null :
                                        <td>
                                            <Field
                                                name={`value_${key}`}
                                                component={Input}
                                                wrapperClassName={'max-w-full mb-5 mr-5'}
                                                type={'number'}
                                                onChange={(value) => {
                                                    updateTotals(key, value);
                                                    //    updateCommissionValues(`value_${key}`, value)
                                                }}
                                                min={0}
                                                step={0.01}
                                                readOnly={commissionItem.readonly && commissionItem.readonly.includes(PRICE_NET)}
                                                validate={validation.required}
                                                readOnlyNoClasses={true}
                                            />
                                        </td>

                                    }

                                    {commissionItem.disabled && commissionItem.disabled.includes(APPLIED_ON) ? null :
                                        <td>
                                            <Field
                                                name={`applied_on_${key}`}
                                                component={Radio}
                                                block={true}
                                                labelClassName={'text-xs flex items-center inline-block outline-none border border-transparent focus:border-primary '}
                                                onChange={(value) => {
                                                    onPriceIs(key, value);
                                                    updateCommissionValues(`applied_on_${key}`, value)
                                                }
                                                }
                                                readOnly={commissionItem.readonly && commissionItem.readonly.includes(APPLIED_ON)}
                                                options={["Net", "Gross"]}
                                                validate={validation.required}
                                                readOnlyNoClasses={true}
                                                size="xs"/>

                                        </td>

                                    }

                                    {commissionItem.disabled && commissionItem.disabled.includes(VAT) ? null :
                                        <td>
                                            <Field
                                                name={`vat_${key}`}
                                                component={Select}
                                                wrapperClassName={'mx-5 text-xs'}
                                                label={''}
                                                disableStyle={true}
                                                labelClassName={'m-0 hidden'}
                                                onChange={(value) => {
                                                    onVat(key, value);
                                                    updateCommissionValues(`vat_${key}`, value)
                                                }
                                                }
                                                options={vatRates && vatRates.length > 0 ? vatRates.map(vat_rate => ({
                                                    value: vat_rate.id,
                                                    text: vat_rate.rate + "%",
                                                    number: vat_rate.rate,
                                                })) : []}
                                                readOnly={commissionItem.readonly && commissionItem.readonly.includes(VAT)}
                                                size="md"/>
                                        </td>
                                    }
                                    {commissionItem.disabled && commissionItem.disabled.includes(PRICE_VAT) ? null :
                                        <td>
                                            <Field
                                                name={`value_netval_${key}`} // nb naming inconsistency is on purpose
                                                component={Input}
                                                hidden={true}

                                                wrapperClassName={'hidden'}
                                            />
                                            <Field
                                                name={`value_vat_${key}`}
                                                component={Input}
                                                onChange={updateCommissionValues}
                                                type={'number'}
                                                min={0}
                                                step={0.01}
                                                validate={commissionItem.disabled &&
                                                (!commissionItem.readonly || !commissionItem.readonly.includes(PRICE_VAT))
                                                    ? validation.required : null}
                                                readOnly={commissionItem.readonly && commissionItem.readonly.includes(PRICE_VAT)}
                                                readOnlyNoClasses={true}
                                            />
                                        </td>

                                    }
                                    {commissionItem.disabled && commissionItem.disabled.includes(PRICE_TOTAL) ? null :
                                        <td>
                                            <Field
                                                name={`final_value_${key}`}
                                                component={Input}
                                                wrapperClassName={'max-w-full mb-5 mr-5'}
                                                type={'number'}
                                                onChange={updateCommissionValues}
                                                min={0}
                                                step={0.01}
                                                readOnly={commissionItem.readonly && commissionItem.readonly.includes(PRICE_TOTAL)}
                                                readOnlyNoClasses={true}
                                            />
                                        </td>

                                    }
                                    {commissionItem.disabled && commissionItem.disabled.includes(COMMISSION) ? null :
                                        <td className={'border-l border-black  '}>
                                            <div className={'pl-5'}>
                                                <Field
                                                    name={`commission_${key}`}
                                                    component={Input}
                                                    onChange={updateCommissionValues}
                                                    readOnly={commissionItem.readonly && commissionItem.readonly.includes(COMMISSION)}
                                                    validate={validation.required}
                                                    size="xs"/>
                                            </div>

                                        </td>

                                    }
                                    {commissionItem.disabled && commissionItem.disabled.includes(PER_PERSON_VAT) ? null :
                                        <td>
                                            {!includesVat ?
                                                <div>
                                                    <span>No</span>
                                                    <input type="hidden" name={`price_includes_vat_${key}`}
                                                           value={false}/>
                                                </div>


                                                :
                                                <Field
                                                    name={`price_includes_vat_${key}`}
                                                    component={Toggle}
                                                    wrapperClassName={''}
                                                    onChange={(value) => {

                                                        updateCommissionValues(`price_includes_vat_${key}`, value)
                                                    }
                                                    }
                                                    readOnly={commissionItem.readonly && commissionItem.readonly.includes(PER_PERSON_VAT)}
                                                    size="xs"/>
                                            }

                                        </td>

                                    }
                                    {commissionItem.disabled && commissionItem.disabled.includes(COMMISSION_VALUE) ? null :
                                        <td>
                                            <Field
                                                name={`commission_value_vat_${key}`}
                                                component={Input}
                                                hidden={true}
                                            />
                                            <span
                                                data-tip
                                                data-for={`commission_value_${key}`}
                                            >
                                                    <Field
                                                        name={`commission_value_${key}`}
                                                        component={Input}
                                                        validate={commissionItem.readonly &&
                                                        commissionItem.readonly.includes(COMMISSION_VALUE)
                                                            ? null : validation.required}
                                                        readOnly={commissionItem.readonly && commissionItem.readonly.includes(COMMISSION_VALUE)}
                                                        readOnlyNoClasses={true}

                                                    />
                                                </span>
                                            {includesVat ?
                                                <ReactTooltip id={`commission_value_${key}`}>
                                                    <span>Net {currencyCode ? currencyCode : '£'}{Number(values[`commission_value_${key}`] - values[`commission_value_vat_${key}`]).toFixed(2)} + {currencyCode ? 'TAX' : 'VAT'} {currencyCode ? currencyCode : '£'}{Number(values[`commission_value_vat_${key}`]).toFixed(2)} = {currencyCode ? currencyCode : '£'}{Number(values[`commission_value_${key}`]).toFixed(2)}  </span>
                                                </ReactTooltip> : null}
                                        </td>


                                    }
                                    <td>
                                        <ButtonBrand small={true} classes={'bg-dark-red mx-5'}
                                                     onClick={() => removeLine(commissionItem)}><i
                                            className="fas fa-trash"/></ButtonBrand>

                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </Panel>
                <div className="flex justify-end content-end">
                    <table>
                        <thead>
                        <tr>
                            <td></td>
                            <td className="text-right">Net</td>
                            <td className="text-right">{currencyCode ? 'Tax' : 'VAT'} ({vat}%)</td>
                            <td className="text-right">Total</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-right font-bold text-xl">Final Commission Value {`(${(currencyCode ? getCurrencySymbol({currency_code: currencyCode}) : '£')})`}</td>
                            <td className="text-right font-bold text-xl">{`${(currencyCode ? getCurrencySymbol({currency_code: currencyCode}) : '£')}`}{finalCommissionValueNet}</td>
                            <td className="text-right font-bold text-xl">{`${(currencyCode ? getCurrencySymbol({currency_code: currencyCode}) : '£')}`}{ finalCommissionVatValue}</td>
                            <td className="text-right font-bold text-xl">{`${(currencyCode ? getCurrencySymbol({currency_code: currencyCode}) : '£')}`}{finalCommissionValue}</td>
                        </tr>
                        {
                            currencyCode && typeof values['exchange_rate'] !== 'undefined' && values['exchange_rate']?
                                <tr>
                                    <td className="text-right text-base pt-2">Est. Final Commission Value {`(£)`}</td>
                                    <td className="text-right text-base pt-2">{`£`}{parseAndRound(finalCommissionValueNet, values['exchange_rate'])}</td>
                                    <td className="text-right text-base pt-2">{`£`}{parseAndRound(finalCommissionVatValue, values['exchange_rate']) }</td>
                                    <td className="text-right text-base pt-2">{`£`}{parseAndRound(finalCommissionValue, values['exchange_rate']) }</td>
                                </tr>

                                : null
                        }

                        </tbody>
                    </table>
                </div>

                <FinancialsComponent
                  event={event}
                  currencyCode={currencyCode}
                  agencyId={event?.agency_id || event?.company?.agency_id}
                />

                <div className={"flex absolute pin-b pin-l"}>
                    <AddNewLine
                      commissionItems={commissionItems}
                      change={change}
                      setCommissionItems={setCommissionItems}
                    />
                    <ReloadFromConfirmation
                      setUpCommissionItems={setUpCommissionItems}
                      data={data}
                      includesVat={includesVat}
                        includesVatRate={includesVatRate}
                        vatRates={vatRates}
                        event={event}
                        currency={event.currency}
                        reduxFormActions={
                            {change: change, dispatch: dispatch, autofill: autofill}
                        }
                    />

                </div>
                <div className={'absolute pin-b pin-r'}>
                    <Field
                        name={`final_commission_value`}
                        component={Input}
                        hidden={true}
                        size="xs"/>
                    <ButtonPrimary
                        type="submit"
                        style={{height: 40, lineHeight: "40px"}}
                    >
                        Save
                    </ButtonPrimary>
                </div>

            </form>
        </Wrapper>
    );
};

FinalCommissionValuesModal = reduxForm({
    form: 'finalCommissionValues',
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: false,
})(FinalCommissionValuesModal);

export default connect(mapStateToProps, mapDispatchToProps)(FinalCommissionValuesModal);
