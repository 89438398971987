import axios from "axios";

const selectAgencyForSuperAgency = async (agencyId = "", token) => {
    try {
        const response = await axios.post(
            `${window.API}/agency/select-agency/${agencyId}`,
            {

            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (!response.data) {
            return {
                result: [],
            };
        }
        return {
            result: response.data
        };
    } catch (error) {
        return { error, result: [] };
    }
};

export {selectAgencyForSuperAgency}