import React, {useState, useEffect, useRef, useMemo} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {selectAgencyForSuperAgency} from "../../services/agencyService/selectAgencyService";
import {isSuperAgent} from "../../modules/auth";
import {fetchSelectedAgencies} from "../../redux/actions";

let visibleTimeoutHandle = null;
const clearVisibleTimeout = (reset = false) => {
    if (visibleTimeoutHandle !== null) {
        clearTimeout(visibleTimeoutHandle);
        visibleTimeoutHandle = null;
    }
}
const SuperAgencySelector = ({
                                 auth, selectedAgencies, fetchSelectedAgencies, token
                             }) => {
    const [agenciesSelected, setAgenciesSelected] = useState([]);
    const [visible, setVisible] = useState(false);
    const mountedRef = useRef()

    useEffect(() => {
        if (selectedAgencies.length > 0 && agenciesSelected.length === 0) {
            let selected = [];
            selectedAgencies.map(agency => selected.push(agency.id));
            setAgenciesSelected(selected);
        }
    }, [selectedAgencies]);
    useEffect(() => {
        if (mountedRef.current !== true) {
            fetchSelectedAgencies(token);
            mountedRef.current = true;
        }
        if (selectedAgencies && selectedAgencies.length > 0) {
            setAgenciesSelected(selectedAgencies.map(agency => agency.id))
        }
    }, []);

    // if the selector is opened, hide it after 9 seconds
    useEffect(() => {
        if (visible) {
            visibleTimeoutHandle = setTimeout(() => setVisible(false), 9000);
        } else {
            clearVisibleTimeout();
        }
        return () => clearVisibleTimeout();
    }, [visible])

    const agencyNames = useMemo(() =>
        agenciesSelected.map(id =>
            auth.agency.id === id ?
                auth.agency.name
                :
                (
                    auth?.agency?.child_agencies ?
                        auth.agency.child_agencies.find(a => a.id === id)?.name
                        :
                        ''
                )
        ).join(', '),
        [agenciesSelected]);
    let selectAgency = (agencyId) => {

        clearVisibleTimeout();
        visibleTimeoutHandle = setTimeout(() => setVisible(false), 7000);

        let selectedAgenciesIds = selectedAgencies.map(agency => agency.id);
        if (selectedAgenciesIds.length === 1 && selectedAgenciesIds[0] === auth.agency.id && agencyId === auth.agency.id) {
        } else {
            selectAgencyForSuperAgency(agencyId, token).then(r => {
                if (typeof r === "object" && r.result) {
                    setAgenciesSelected(Object.values(r.result))
                } else {
                    setAgenciesSelected(r.result);
                }
                fetchSelectedAgencies(token)
            });
        }
    }

    if (!auth?.agency?.is_super_agent || !auth?.agency?.child_agencies || auth?.agency?.child_agencies.length <= 0) {
        return null;
    }

    return (<div className={'flex flex-col flex-0 justify-center mr-4 relative'}>
            <div className={'flex justify-end'}>
            <span
                title={`Currently displaying data for: ${agencyNames}`}
                className="text-right rounded-full bg-white p-2 hover:bg-green text-black hover:text-white cursor-pointer"
                onClick={() => {
                    setVisible(!visible);
                }}
            >
                <i className="fal fa-users font-bold"/>
            </span>
                {visible && (<div
                        className={'text-white border-2 border-green p-2 absolute bg-black z-20'}
                        style={{top: '40px', right: '40px'}}
                    >
                        <div className={'flex flex-wrap'}>
                            <p className={'font-bold text-xs'}>Agency data to show:</p>
                            <label className={'flex justify-between items-center w-full cursor-pointer hover:text-green'}>
                                {auth.agency.name}
                                <input className={'ml-5'} checked={agenciesSelected.includes(auth.agency.id)}
                                       type="checkbox" onChange={(e) => {
                                    selectAgency(auth.agency.id)
                                }}/>

                            </label>
                            {auth.agency.child_agencies.map(childAgency => {
                                return <label className={'flex justify-between items-center w-full mt-1 cursor-pointer hover:text-green'} key={childAgency.id}>
                                    {childAgency.name}
                                    <input className={'ml-5'}
                                           checked={agenciesSelected.includes(childAgency.id)} type="checkbox"
                                           onChange={(e) => {
                                               selectAgency(childAgency.id)
                                           }}/>

                                </label>
                            })}
                        </div>
                    </div>

                )}
            </div>

        </div>

    )
}


export default withRouter(connect(state => ({
    auth: state.auth, isSuperAgent: isSuperAgent(state), token: state.auth.token, selectedAgencies: state.selectedAgencies.selectedAgencies
}), {
    fetchSelectedAgencies
})(SuperAgencySelector));

