import React, { useEffect, useState} from 'react';
import {Link, useLocation, useParams, withRouter} from "react-router-dom";
import useService from "../../../../hooks/useService";
import {getEnquiryById} from "../../../../services/rfpService";
import Loading from "../../../../Components/Loading";
import Warning from "../../../../Components/Warning";
import BriefSummary from "../../../../Components/RfpBriefSection/BriefSummary";
import {reduxForm, getFormSubmitErrors, isPristine} from "redux-form";
import {ButtonBrand, ButtonPrimary} from "../../../../Components/Button";
import submit from "./Helpers/submitUser";
import {connect, useDispatch} from "react-redux";
import {ENQUIRY_VENUE_DATA_STATUS} from '../../../../modules/rfp/enquiryVenueDataStatus';

import {isAgency, isAgent, isInternal, isVenueAdmin, token} from '../../../../modules/auth';
import {WhitePanelWithHeader} from "../../../../ui";
import {
    buildCurrencyFormatter, checkBox,
    handleForm,
    setInitialDelegates, setInitialMinDelegates,
    setInitialRemove,
     validateForm, warnForm
} from "./Helpers/formHelper";
import ConfirmationSidePanel from "./Components/ConfirmationSidePanel";
import PageBreakdown from "./Components/PageBreakdown";
import ConfirmationComplete from "./Components/ConfirmationComplete";

import { fetchVatRates } from '../../../../redux/actions';
import {Failed} from "../../../../Components/Form";
import moment from 'moment';
import DateOverrideNotification from "../../../../Components/Rfp/DateOverrideNotification";

let currentVat = 20;
let currentVatRateForCommission = 20;
let currencyFormatter = null;

let initialDelegateValues = {};
let initialRemoveValues = {};
let initialMin = {};

//const v = {
//    minValue:  min => value =>
//        value && value < min ? `Must be at least ${min}` : undefined
//
//};

let ConfirmBooking = (props) => {

    const {id, enquiryVenueDataId} = useParams();
//    const location = useLocation();
    const [enquiryVenueData, setEnquiryVenueData] = useState(null);
    const [anyValueChanged, setAnyValueChanged] = useState(null);
    const [submitting, setSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchVatRates(props.token))
    }, [dispatch,props.token]);

    const mappedVatRates = props.vatRates && props.vatRates.vatRates && props.vatRates.vatRates.length > 0 ? props.vatRates.vatRates.map(vat_rate => ({
        value: vat_rate.id,
        text: vat_rate.rate + "%",
        number: vat_rate.rate,
    })) : [];


    const {loading, data, error} = useService({
        service: getEnquiryById,
        trigger: id,
        params: id,
    });

    if (loading) {
        return <Loading large/>;
    }

    if (error) {
        return <Warning>There was a problem fetching data</Warning>;
    }

    //Setting Initial Values after getting enquiryVenueDatas, probs a better way of writing or moving this.
    if (data && data.enquiry_venue_datas.filter(datas => datas.id === parseInt(enquiryVenueDataId)).length === 0) {
        return <Warning>There was a problem fetching data</Warning>;
    } else {
        if (!enquiryVenueData) {
            setEnquiryVenueData(data.enquiry_venue_datas.find(datas => datas.id === parseInt(enquiryVenueDataId)));
        } else {
            initialRemoveValues = setInitialRemove(enquiryVenueData);
            initialDelegateValues = setInitialDelegates(enquiryVenueData);
            initialMin = setInitialMinDelegates(enquiryVenueData);
            currencyFormatter = buildCurrencyFormatter(enquiryVenueData)
        }
    }

    let submitForm = (values) => {
        setSubmitting(true);
        handleForm(values, submit, props, !Boolean(anyValueChanged), setSubmitting, setEnquiryVenueData, setSubmitError, setErrorMessage);
        window.scrollTo(0, 0);
    }
    if (!enquiryVenueData || mappedVatRates.length < 1) {
        return <Loading large/>;
    }

    const renderCheckBox = ({input}) => {
        return checkBox(input);
    };
    return (
        <div>
            <div className="md:flex justify-between items-center ">
                <h1 className={'mb-5 mt-5 text-xl sm:text-2xl lg:text-3xl'}>
                    Confirming your event {data.brief.event_name}
                </h1>
                <div className="text-lg mb-3 mt-3">
                    <p>Ref: {data.client_details.ref_id}</p>
                    <p>Version: {enquiryVenueData && enquiryVenueData.proposal_data && enquiryVenueData.proposal_data.amendment_requests ? `${enquiryVenueData.proposal_data.amendment_requests.length + 1}` : 1}</p>
                    {data.managing_trinity_user ?
                      <span className={'ftd-rfp-managed'}>Managed</span>
                    : null}
                </div>

            </div>

            <div className={'row'}>
                <ConfirmationSidePanel enquiryVenueData={enquiryVenueData} data={data}/>
                <div className={'px-3 col-w-full xl:col-w-5/6'}>
                    <div className="w-full">
                        {(enquiryVenueData && enquiryVenueData.status >= ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED) ?
                            <ConfirmationComplete enquiry={data} enquiryVenueData={enquiryVenueData} isEnquirer={true}/>
                            : null }
                        {data.managing_trinity_user  && enquiryVenueData.status <= ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED ?
                            <div className="bg-primary px-3 py-3 font-bold mb-3 text-white">
                                <p className={'mb-0'}>This is a managed enquiry confirmation on behalf of the client</p>
                                {data.managing_trinity_user && (props.isAgency || props.isInternal) && enquiryVenueData.status === ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED ?
                                    <Link to={{ pathname: `/admin/rfp/${data.id}/confirm/${enquiryVenueData.id}` }} className={'w-full'}>
                                        <ButtonBrand
                                            classes="p-3  my-2"
                                            style={{ height: 'auto', lineHeight: 1 }}
                                        >
                                            Visit Venue Confirmation screen
                                        </ButtonBrand>
                                    </Link>
                                    : null
                                }
                            </div>
                            : null}

                        <BriefSummary admin  {...data.brief} enquiryVenueData={enquiryVenueData} />
                        {(enquiryVenueData && enquiryVenueData.status >= ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED )
                            ? null :
                                <div className={'mb-2 mb-4 text-lg'}>
                                    <p>Thank you for choosing to confirm your event
                                        with {enquiryVenueData.venue.name}. Below is the summary from your proposal, please
                                        edit numbers or uncheck to remove the items no longer required.</p>
                                </div>
                        }



                        <WhitePanelWithHeader icon="far fa-clipboard" title="Event Confirmation">
                            <DateOverrideNotification data={data} enquiryVenueData={enquiryVenueData}/>
                            {submitting ? <Loading inline/> :
                                <div className={'RFP'}>
                                    {enquiryVenueData && (Number(enquiryVenueData.status) === ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS || Number(enquiryVenueData.status) === ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED)
                                        ?
                                        <span className='bg-green px-3 py-4 mb-5 block font-bold'>
                                            Thank you for your confirmation, this information has been sent to {enquiryVenueData.venue.name} and they will be in touch shortly
                                        </span>
                                        : null}
                                </div>

                            }


                            <form noValidate onSubmit={props.handleSubmit(submitForm)}>
                                <PageBreakdown
                                    brief={data.brief}
                                    currentVatRateForCommission={currentVatRateForCommission}
                                    vatRates={mappedVatRates}
                                    enquiryVenueData={enquiryVenueData}
                                    currentVat={currentVat}
                                    renderCheckBox={renderCheckBox}
                                    currencyFormatter={currencyFormatter}
                                    accommodationRemoveRow={props.accommodationRemoveRow}
                                    accommodationNoOfDelegates={props.accommodationNoOfDelegates}
                                    meetingRoomRemoveRow={props.meetingRoomRemoveRow}
                                    errors={props.submitErrors}
                                    showCommission={ (props.isVenueAdmin || props.isAgency) && enquiryVenueData && enquiryVenueData.proposal_data.commission_items}
                                    initialValues={{
                                        delegates: initialDelegateValues,
                                        remove: initialRemoveValues,
                                    }}
                                    setAnyValueChanged={setAnyValueChanged}
                                />

                                <div className={'mt-6'}>

                                    {(enquiryVenueData && enquiryVenueData.status >= ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED && enquiryVenueData.status < ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED)
                                        ?
                                        <div className={'bg-edge-light-blue p-3 mb-6 font-bold'}>
                                            <p className={'mb-0'}>Once the venue has confirmed the booking, we will release all other venues and send them an email</p>
                                        </div>
                                        :
                                        null
                                    }

                                    {(Boolean(anyValueChanged) === false && data.enquiry_venue_datas.length > 1 && enquiryVenueData && enquiryVenueData.status < ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) && (
                                      <div className={'bg-edge-light-blue p-3 mb-6 font-bold'}>
                                          <p className={'mb-0'}>Submitting this confirmation will release all other venues and send them an email rejecting them from this proposal</p>
                                      </div>
                                    )}

                                    {Boolean(anyValueChanged) === true
                                        ?
                                        <div className={'bg-edge-light-blue p-3 mb-4 font-bold'}>
                                            <p className={'mb-0'}>As your requirements have changed since the most recent proposal, details and costs require updating or confirming by the venue - please allow 24 hours for a response, before returning here to confirm your booking</p>
                                        </div>
                                        :
                                        null}
                                    <div
                                        className={(enquiryVenueData && (Number(enquiryVenueData.status) === Number(ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) || Number(enquiryVenueData.status) > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS) ? ' hidden' : '')}>
                                        {Boolean(anyValueChanged) === true
                                            ?
                                            <ButtonBrand type="submit" classes={'text-xl p-8 ml-auto'}
                                            >
                                                {submitting ? <Loading inline/> : "Submit to the Venue"}
                                            </ButtonBrand>
                                            :
                                            <ButtonBrand type="submit" classes={'text-xl p-8 ml-auto'}
                                            >
                                                {submitting ? <Loading inline/> : "Confirm Booking"}
                                            </ButtonBrand>}
                                    </div>

                                </div>

                            </form>
                            {submitError ? <Failed text={errorMessage} /> : null}
                        </WhitePanelWithHeader>

                    </div>
                </div>
            </div>
        </div>


    )

}

function validate(values) {
    return validateForm(values)
}


function warn(values) {
    return warnForm(values)
}


let ConfirmBookingForm = reduxForm({
    form: 'confirmBooking', // a unique identifier for this form
    enableReinitialize: true, // this is needed!!
    validate,
    warn // <--- warning function given to redux-form
})(ConfirmBooking)


let ConfirmBookingWithRedux = connect((state, props) => {

    return {
        submitErrors: getFormSubmitErrors('confirmBooking')(state),
        isVenue: isVenueAdmin(state),
        isAgency: isAgency(state),
        isInternal: isInternal(state),
        token: token(state),
        vatRates: state.vatRate,
        pristine: isPristine('confirmBooking'),
        initialValues: {
            delegates: initialDelegateValues,
            remove: initialRemoveValues,
            minDelegates: initialMin
        }
    }


})(ConfirmBookingForm)

export default withRouter((ConfirmBookingWithRedux));
