import React, {Component} from 'react';
import {connect} from "react-redux";
import {token, company, isAgencyAdmin, isAgencyUser} from "../../../../modules/auth";
import {
  fetchCorporateAccount, fetchCorporateAccountDocumentCategories,
  fetchCorporateAccountDocumentsByCategory,
  unloadCorporateAccountDocuments,
} from "../../../../redux/actions";
import CorporateAccountDocumentByCategoryList from "./CorporateAccountDocumentByCategoryList";
import AdminPageWIthSideBarDynamic from "../../../../Components/Layout/AdminPageWIthSideBarDynamic";
import {sectionTypes} from "../sidebarSections";
import Loading from "../../../../Components/Loading";
import {isEdgeSupport, isRoleEdgeAdmin} from "../../../../modules/permissions";
import NotFound from "../../../NotFound";

const mapStateToProps = state => {
  return {
    ...state.corporateAccount,
    ...state.corporateAccountDocumentsList,
    ...state.corporateAccountDocumentsCategoryList,
    company: company(state),
    token: token(state),
    userRoles: state.auth.roles,
    documentFetching: state.corporateAccountDocumentsList.isFetching,
    categoryFetching: state.corporateAccountDocumentsCategoryList.isFetching,
    corporateAccountId: state?.auth?.company?.corporate_account_id,
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isEdgeSupport: isEdgeSupport(state.auth),
    isAgencyAdmin: isAgencyAdmin(state),
    isAgencyUser: isAgencyUser(state),
  }
}

const mapDispatchToProps = {
  fetchCorporateAccountDocumentsByCategory,
  fetchCorporateAccountDocumentCategories,
  unloadCorporateAccountDocuments,
  fetchCorporateAccount
}

class CorporateAccountDocumentByCategoryListContainer extends Component {
  componentDidMount() {
    const {match, token} = this.props;
    let id = typeof this.props.company !== 'undefined' && this.props.company ? this.props.company.corporate_account.id : match.params ? match.params.slug : null;
    if (id) {
      this.props.fetchCorporateAccount(id, token);
      this.props.fetchCorporateAccountDocumentsByCategory(id, this.props.match.params.category, token);
      this.props.fetchCorporateAccountDocumentCategories(id, this.props.token);
    }
  }

  componentWillUnmount() {
    this.props.unloadCorporateAccountDocuments();
  }

  render() {
    const {corporateAccountDocuments, isFetching, corporateAccount,isRoleEdgeAdmin, corporateAccountId, match, isEdgeSupport, isAgencyAdmin, isAgencyUser } = this.props;

    const accountId = this.props.match.params.slug ?? corporateAccountId;

    if(accountId != corporateAccountId && !isRoleEdgeAdmin && !isAgencyAdmin && !isEdgeSupport && !isAgencyUser)
    {
      return <NotFound/>
    }

    const isCorp = match.path.startsWith('/corporate');

    if(this.props.userRoles.includes('company_user', 'head_of_events', 'head_of_procurement', 'event_specialist')) {
        return(
            <CorporateAccountDocumentByCategoryList
                documents={corporateAccountDocuments.data}
                isFetching={this.props.documentFetching || this.props.categoryFetching}
                accountId={this.props.match.params.slug}
                userRoles={this.props.userRoles}
                category={this.props.corporateAccountCategories && this.props.corporateAccountCategories.length > 0 ? this.props.corporateAccountCategories.find((cat) => parseInt(cat.id) ===  parseInt(this.props.match.params.category)) : null}
                withoutSidebar={true}
                token={this.props.token}
                isCorp={isCorp}
            />
        )
    }
    if(typeof corporateAccount.account_name === 'undefined')
    {
      return (
          <div>
            <Loading/>
          </div>
      )
    }



    return (
      <AdminPageWIthSideBarDynamic
        sectionTypes={sectionTypes}
        match={this.props.match}
        headerText={`Manage ${corporateAccount.account_name}`}
        basePath={`/admin/corporate-accounts/${corporateAccount.id}/`}
      >
        <CorporateAccountDocumentByCategoryList
          documents={corporateAccountDocuments.data}
          isFetching={isFetching}
          accountSlug={this.props.company ? this.props.company.id : null}
          userRoles={this.props.userRoles}
          category={this.props.corporateAccountCategories.find((cat) => parseInt(cat.id) ===  parseInt(this.props.match.params.category))}
          token={this.props.token}
          isCorp={isCorp}
        />
      </AdminPageWIthSideBarDynamic>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountDocumentByCategoryListContainer)
