import React, {Component} from 'react';
import {connect} from "react-redux";
import {token, company, isCorporate, isAgencyAdmin, isAgencyUser} from "../../../../modules/auth";
import {
    fetchCorporateAccountDocumentCategories,
    unloadCorporateAccountDocumentCategories,
} from "../../../../redux/actions";
import CorporateAccountDocumentList from "./CorporateAccountDocumentList";
import CorporateAccountDocumentCategoryList from "./CorporateAccountDocumentCategoryList";
import {corporateAccountDocumentsCategoryList} from "../../../../redux/reducers/corporate-documents";
import {corporateAccountId, isEdgeSupport, isRoleEdgeAdmin,} from "../../../../modules/permissions";
import NotFound from "../../../NotFound";

const mapStateToProps = state => ({
    ...state.corporateAccountDocumentsCategoryList,
    company: company(state),
    token: token(state),
    corporateAccountId: corporateAccountId(state.auth),
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isCorporate: isCorporate(state),
    isEdgeSupport: isEdgeSupport(state.auth),
    isAgencyAdmin: isAgencyAdmin(state),
    isAgencyUser: isAgencyUser(state),
})

const mapDispatchToProps = {
    fetchCorporateAccountDocumentCategories,
    unloadCorporateAccountDocumentCategories
}

class CorporateAccountDocumentCategoryListContainer extends Component {

    getAccountId() {
        const { match, isCorporate, corporateAccountId } = this.props;
        if (isCorporate) {
            return corporateAccountId;
        }
        return typeof this.props.company !== 'undefined' && this.props.company ? this.props.company.corporate_account.id : match.params ? match.params.slug : null;
    }

    componentDidMount() {
            this.props.fetchCorporateAccountDocumentCategories(this.getAccountId(), this.props.token);
    }

    componentWillUnmount() {
        this.props.unloadCorporateAccountDocumentCategories();
    }

    render() {
        const {corporateAccountCategories, isFetching, corporateAccountId, isRoleEdgeAdmin, isCorporate, match, isEdgeSupport, isAgencyAdmin, isAgencyUser} = this.props;

        const accountId = isCorporate ? corporateAccountId : this.props.match.params.slug;

        const isCorp = match.path.startsWith('/corporate');

        if(accountId != corporateAccountId && !isRoleEdgeAdmin && !isAgencyAdmin && !isEdgeSupport && !isAgencyUser)
        {
            return <NotFound/>
        }

        return <CorporateAccountDocumentCategoryList categories={corporateAccountCategories} isFetching={isFetching}  accountSlug={this.getAccountId()} isCorp={isCorp} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountDocumentCategoryListContainer)