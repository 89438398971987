import {
  CREATE_INDUSTRY_EVENT,
  CREATE_PUBLIC_EVENT,
  EVENT_DELETED_REQUEST,
  EVENT_UNLOAD,
  INDUSTRY_EVENTS_LIST_ERROR,
  INDUSTRY_EVENTS_LIST_RECEIVED,
  INDUSTRY_EVENTS_LIST_REQUEST,
  INDUSTRY_EVENTS_LIST_UNLOAD,
  PUBLIC_EVENTS_LIST_ERROR,
  PUBLIC_EVENTS_LIST_RECEIVED,
  PUBLIC_EVENTS_LIST_REQUEST,
  PUBLIC_EVENTS_LIST_UNLOAD,
  EVENTS_LIST_RECEIVED_ERROR,
  EVENTS_LIST_RECEIVED,
  EVENTS_LIST_REQUEST,
  EVENTS_LIST_UNLOAD,
  EVENTS_SINGLE_REQUEST,
  EVENTS_SINGLE_RECEIVED,
  EVENTS_SINGLE_RECEIVED_ERROR,
  EVENTS_SINGLE_UNLOAD,
  EVENTS_SINGLE_CONFIRMATION_UPLOAD,
  EVENTS_SINGLE_CONFIRMATION_DELETE_FILE,
  EVENTS_SINGLE_FINAL_COMMISSION_VALUES,
  EVENTS_SINGLE_FINAL_COMMISSION_VALUES_ERROR,
  EVENTS_SINGLE_INVOICED_RECEIVED,
  EVENTS_SINGLE_INVOICED_RECEIVED_ERROR,
  BOOKED_EVENTS_UNLOAD,
  OWN_EVENTS_LIST_RECEIVED,
  OWN_EVENTS_UNLOAD,
  BOOKED_EVENTS_LIST_RECEIVED,
  EVENTS_CHASED_RECEIVED,
  EVENT_STATUS_LIST,
  EVENT_STATUS_LIST_RECEIVED,
  EVENT_STATUS_LIST_RECEIVED_ERROR, EVENT_STATUS_LIST_UNLOAD
} from "./types";

export const publicEvents = (state = {
  publicEvents: [],
  isFetching: false,
  newEvent: false,
  editEvent: false
}, action) => {
  switch (action.type) {
    case PUBLIC_EVENTS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUBLIC_EVENTS_LIST_RECEIVED:
      return {
        ...state,
        ...state,
        isFetching: false,
        publicEvents: action.data.data
      }
    case PUBLIC_EVENTS_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case PUBLIC_EVENTS_LIST_UNLOAD:
      return {
        ...state,
        isFetching: false,
        publicEvents: [],
      }
    case CREATE_PUBLIC_EVENT:
      state.publicEvents.push(action.data.data.data)
      return {
        ...state,
        publicEvents: state.publicEvents,
        isFetching: false,
        event: [],
        newEvent: false,
        editEvent: false,
      }
    case EVENT_UNLOAD:
      return {
        ...state,
        isFetching: false,
        event: [],
        newEvent: false,
        editEvent: false,
      }
    case EVENT_DELETED_REQUEST:
      let pubEvts = state.publicEvents.filter(pubEvt => {
        return pubEvt.id !== action.data.id
      });
      return {
        ...state,
        publicEvents: pubEvts,
        editEvent: false,
        newEvent: false
      }
    default:
      return state;
  }
}

export const industryEvents = (state = {
  industryEvents: [],
  isFetching: false,
  editEvent: false,
  newEvent: false,
}, action) => {
  switch (action.type) {
    case INDUSTRY_EVENTS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case INDUSTRY_EVENTS_LIST_RECEIVED:
      return {
        ...state,
        isFetching: false,
        industryEvents: action.data.data
      }
    case CREATE_INDUSTRY_EVENT:
      state.industryEvents.push(action.data.data.data)
      return {
        ...state,
        industryEvents: state.industryEvents,
        isFetching: false,
        event: [],
        newEvent: false,
        editEvent: false,
      }
    case INDUSTRY_EVENTS_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case INDUSTRY_EVENTS_LIST_UNLOAD:
      return {
        ...state,
        isFetching: false,
        industryEvents: [],
      }
    case EVENT_DELETED_REQUEST:
      let indEvents = state.industryEvents.filter(ind => {
        return ind.id !== action.data.id;
      });
      return {
        ...state,
        industryEvents: indEvents,
        editEvent: false,
        newEvent: false
      }
    default:
      return state;
  }
}

export const bookedEvents = (state = {
  bookedEvents: [],
  ownEvents: [],
  error: false,
  isFetchingBookedEvents: false,
  bookedEventsPage: 1,
  bookedEventsLastPage: 1,
}, action) => {
  switch (action.type) {
    case EVENTS_LIST_REQUEST:
      return {
        ...state,
        isFetchingBookedEvents: true,
      };
    case EVENTS_LIST_RECEIVED:
      return {
        ...state,
        bookedEvents: action.data.data,
        isFetchingBookedEvents: false,
        bookedEventsPage: action.data.meta.current_page,
        bookedEventsLastPage: action.data.meta.last_page
      };
    case EVENTS_CHASED_RECEIVED:
      let events = ([...state.bookedEvents.map(event => {
        if (event.data.id === action.data.data.id) {
          return {data: action.data.data}
        }
        return event
      })]);
      return {
        ...state,
        bookedEvents: events,
        isFetchingBookedEvents: false,
      };
    case BOOKED_EVENTS_UNLOAD:
      return {
        ...state,
        bookedEvents: []
      }
    case EVENTS_LIST_RECEIVED_ERROR:
      return {
        ...state,
        bookedEvents: [],
        isFetchingBookedEvents: false,
        error: true,
      };
    case EVENTS_LIST_UNLOAD:
      return {
        ...state,
        bookedEvents: [],
        error: false,
        isFetchingBookedEvents: false,
        bookedEventsPage: 1
      };
    case OWN_EVENTS_LIST_RECEIVED:
      return {
        ...state,
        ownEvents: action.data.data,
        error: false
      }
    case OWN_EVENTS_UNLOAD:
      return {
        ...state,
        ownEvents: []
      }
    case BOOKED_EVENTS_LIST_RECEIVED:
      return {
        ...state,
        bookedEvents: action.data.data,
        error: false,
        isFetchingBookedEvents: false,
      }
    default:
      return state;
  }
}


export const bookedEvent = (state = {
  bookedEvent: null,
  error: false,
  isFetchingBookedEvent: false,
}, action) => {
  switch (action.type) {
    case EVENTS_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingBookedEvent: true,
      };
    case EVENTS_SINGLE_RECEIVED:
      return {
        ...state,
        bookedEvent: action.data.data,
        isFetchingBookedEvent: false,
      };
    case EVENTS_SINGLE_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingBookedEvent: false,
        error: true,
      };
    case EVENTS_SINGLE_UNLOAD:
      return {
        ...state,
        bookedEvent: false,
        error: false,
        isFetchingBookedEvent: false,
      };
    case EVENTS_SINGLE_CONFIRMATION_DELETE_FILE:
    case EVENTS_SINGLE_FINAL_COMMISSION_VALUES:
    case   EVENTS_SINGLE_INVOICED_RECEIVED:
    case EVENTS_SINGLE_CONFIRMATION_UPLOAD:
      return {
        ...state,
        bookedEvent: action.data.data,
        isFetchingBookedEvent: false,
      };
    case EVENTS_SINGLE_FINAL_COMMISSION_VALUES_ERROR:
    case EVENTS_SINGLE_INVOICED_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingBookedEvent: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const eventStatus = (state = {
  eventStatus: null,
  error: false,
  isFetchingEventStatusList: false,
}, action) => {
  switch (action.type) {
    case EVENT_STATUS_LIST:
      return {
        ...state,
        isFetchingEventStatusList: true
      }
    case EVENT_STATUS_LIST_RECEIVED:
      return {
        ...state,
        isFetchingEventStatusList: false,
        eventStatus: action.data,
        error: false
      }
    case EVENT_STATUS_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingEventStatusList: false,
        error: true
      }
    case EVENT_STATUS_LIST_UNLOAD:
      return {
        ...state,
        isFetchingEventStatusList: false,
        error: false,
        eventStatus: null
      }
    default: return state;
  }
}