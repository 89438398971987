import * as CORPORATE_ROUTES from "../../../Router/corporate_routes";
import React from "react";
let UnapprovedAgency = ({userAgency }) => {
    return (
        <div className='bg-primary px-3 py-6 my-5 block font-bold text-white'>
            <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                {'Hold tight - we just need to approve your new agency. Once we\'ve done that, you\'ll be able to log in to view your RFPs, manage your companies and invite other agency users. Check back again in an hour (Mon-Fri 9-5pm)'}
            </p>
            <p className={'mt-2'}>
                In the meantime,
                please {userAgency.can_manage_invoicing ? ':' : ' complete your Agency profile'} {userAgency.can_manage_invoicing ? <></> :
                <a href={CORPORATE_ROUTES.FRONTEND.AGENCY.EDIT.replace(':id', userAgency.id).replace(':section', 'edit')}>here</a>} {userAgency.can_manage_invoicing ? '' : ' - your address, phone number and logo'}
            </p>
            {userAgency.can_manage_invoicing ?
                <ol>
                    <li>Complete your Agency profile <a
                        href={CORPORATE_ROUTES.FRONTEND.AGENCY.EDIT.replace(':id', userAgency.id).replace(':section', 'edit')}>here</a> -
                        your address, phone number and logo
                    </li>
                    <li>Enter financial details to include on any commission invoicing</li>
                    <li>Configure any nominal codes used when exporting invoices to Xero</li>
                </ol> : null}
        </div>
    )
}

export default UnapprovedAgency;