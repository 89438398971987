import React, {useState} from "react";
import {connect } from "react-redux";

import {isAgency, isCorporate, isRolePlatformAdmin, userAgencyObject} from "modules/auth";
import {isAdminSupportOrFinance, isRoleEdgeAdmin} from "../../../modules/permissions";


const mapStateToProps = (state, props) => {
    return {
        userAgency: userAgencyObject(state),
        isAgency: isAgency(state),
        isCorporate: isCorporate(state),
        isAdminSupportOrFinance: isAdminSupportOrFinance(state.auth),
        user: state.auth.user
    };
};
const mapDispatchToProps = {

}
let ManagingAgentBanner = ({enquiry, isCorporate, isAgency, userAgency, user, isAdminSupportOrFinance}) => {

    if(enquiry.managing_trinity_user && enquiry.managing_trinity_user.id !== user.id) {

        if((isAgency || isAdminSupportOrFinance) && enquiry.managing_trinity_user && enquiry.managing_trinity_user.agency && enquiry.company_details.name && enquiry.company_details.name.agency )
        {
            return (
                <div className='bg-warning px-3 py-6 my-5 block font-bold text-white'>
                    <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                        {`${enquiry.managing_trinity_user.name} (${enquiry.managing_trinity_user.agency.name}) is managing this RFP for ${enquiry?.company_details?.name?.agency?.name}`}
                    </p>
                </div>
            )
        }
        if(isCorporate && enquiry.managing_trinity_user && enquiry.managing_trinity_user.agency && enquiry.company_details.name && enquiry.company_details.name.agency )
        {
            return (
                <div className='bg-warning px-3 py-6 my-5 block font-bold text-white'>
                    <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                        {`${enquiry.managing_trinity_user.name} is managing this RFP for ${enquiry?.company_details?.name?.agency?.name}`}
                    </p>
                </div>
            )
        }
        return (
            <></>
        )
    }
    return (
        <></>
    )
}

export default connect(mapStateToProps,     mapDispatchToProps)(ManagingAgentBanner);
