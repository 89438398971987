import React from "react";
import { Field } from "redux-form";
import { Input } from "../../Components/Form";
import { briefPreferences as v } from "./validate";

const BriefPreferences = ({ editEnquiry = false, hasVenues = false }) => {
  return (
    <div className="row">
      <div className="col col-w-full">
        <div className="mb-4 mt-4 lg:mt-2">
          {editEnquiry ? (
            <h4>Your event preferences</h4>
          ) : (
            <>
              {hasVenues ? (
                <h4>Please provide some additional background information for the event</h4>
              ) : (
                <h4>Want one of our event professionals to suggest suitable venues?</h4>
              )}
            </>
          )}
        </div>
        <div className="row">
          <div className="col col-w-full md:col-w-1/3">
            <Field
              name="brief_location"
              label="Approximate location"
              component={Input}
              wrapperClassName="w-full mb-8 mr-2"
              validate={v.location}
              placeholder={editEnquiry ? "Not Provided" : ""}
            />
          </div>
          <div className="col col-w-1/3">
            <Field
              name="brief_event_type"
              label="Type of event"
              component={Input}
              wrapperClassName="w-full mb-8"
              validate={v.event_type}
              placeholder={editEnquiry ? "Not Provided" : ""}
            />
          </div>
          <div className="col col-w-1/3">
            <Field
              name="brief_budget"
              label="Approximate Net Budget (exc. VAT/tax)"
              component={Input}
              wrapperClassName="w-full mb-8"
              validate={v.budget}
              placeholder={editEnquiry ? "Not Provided" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefPreferences;
