import axios from "axios";

const getVenue = async slug => {
  try {
    const response = await axios.get(`${window.API}/venues/${slug}`);
    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

const getVenueById = async (id = "") => {
  try {
    const response = await axios.get(`${window.API}/venues-by-id/${id}`);
    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

const getVenues = async ids => {
  try {
    const response = await axios.get(`${window.NODE_AS}/compare`, {
      params: {
        venues: ids,
      },
    });
    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

export { getVenue, getVenues, getVenueById };
