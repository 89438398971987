import {
  NOMINAL_CODE_EDIT_REQUEST_RECEIVED,
  NOMINAL_CODE_LIST_ERROR,
  NOMINAL_CODE_LIST_RECEIVED,
  NOMINAL_CODE_LIST_REQUEST,
  NOMINAL_CODE_LIST_UNLOAD,
} from "./types";

export const nominalCodes = (state = {
  nominalCodes: {},
  isFetchingCodes: false,
  error: false,
  errorData: {},
}, action) => {
  switch (action.type) {
    case NOMINAL_CODE_LIST_REQUEST:
      return {
        ...state,
        isFetchingCodes: true,
        error: false,
      };
    case NOMINAL_CODE_LIST_RECEIVED:
      return {
        ...state,
        isFetchingCodes: false,
        nominalCodes: action.data ? action.data.data.data : {},
        error: false,
      };
    case NOMINAL_CODE_EDIT_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingCodes: false,
        nominalCodes: state.nominalCodes.map(nominalCode => nominalCode.id === action.data?.data?.id ? {
          ...nominalCode, ...action.data.data
        } : nominalCode),
        error: false,
      }
    case NOMINAL_CODE_LIST_UNLOAD:
      return {
        ...state,
        isFetchingCodes: false,
        nominalCodes: {},
        error: false,
      };
    case NOMINAL_CODE_LIST_ERROR:
      return {
        ...state,
        isFetchingCodes: false,
        errorData: action.data.error,
        error: true,
      };
    default:
      return state;
  }
};
