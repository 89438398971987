import React, { useEffect, useRef, useState } from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFormSubmitErrors, destroy, SubmissionError } from "redux-form";
import { Panel, ProgressBar, Toggle } from "ui";
import {
  isAgency,
  isCorporate,
  isInternal as isInternalSelector,
  isSuperAgent,
  isVenue,
  user,
  userAgencyObject
} from "modules/auth";
import Loading from "Components/Loading";
import useParams from "hooks/useParams";
import useService from "hooks/useService";
import { token as tokenSelector } from "modules/auth";
import mapServerErrors from "modules/map-server-errors";
import { getVenues } from "services/venueService";
import {submitEnquiry} from "services/rfpService";
import {pushCheckOutStep, pushSubmitEnquiry} from "../../modules/google-tag-manager";
import {getRoomStyles} from "../../services/rfpService";
import {getPages} from "./pages";
import moment from "moment";
import Enquiry from "./Enquiry";
import { ENQUIRY_STATUS, ROCHE_COMPANY_CODE } from "../../constants";
import {companyObject, corporateAccountObject} from "../../modules/permissions";
import { Field, getFormValues, reduxForm } from "redux-form";
import AuthorisationTriggeredBanner from "./AuthorisationTriggeredBanner";

const form = "rfp_enquiry";
const mapStateToProps = (state, props) => {
  const template = props.location?.state?.template;

  const corporateAccount = corporateAccountObject(state.auth);
  const company = companyObject(state.auth);
  let enquiryFormDisabled = false;
  let showFormToggle = false;
  let superAgencyCode = null;
  let disableMaxDelegates = false;

  let offerRfpXpress = false;
  // checks for rfp xpress
  if (user(state) && user(state).agency) { // if logged-in users agency offers rfp xpress
    offerRfpXpress = Boolean(user(state).agency.offer_rfp_xpress_service);
  }
  if (company && company.agency) { // if companies agency offers rfp xpress
    offerRfpXpress = Boolean(company.agency.offer_rfp_xpress_service)
    if (company.agency.is_super_agent) {
      superAgencyCode = company.agency.id
    }
  }
  if (user(state) && corporateAccount && corporateAccount.agency) { // if logged-in users corporate account agency offers rfp xpress
    offerRfpXpress = Boolean(corporateAccount.agency.offer_rfp_xpress_service)
  }
  if (company) { // if company offers rfp xpress
    offerRfpXpress = Boolean(company.offer_rfp_xpress_service)
    if (company.code === ROCHE_COMPANY_CODE && isCorporate(state)) {
      // enquiryFormDisabled = true
      // showFormToggle = true
      disableMaxDelegates = true;
    }
  }
  if (props.enquiry) {
    let venueIds = [];
    props.enquiry.enquiry_venue_datas && props.enquiry.enquiry_venue_datas.map(vd => {
      venueIds.push(vd.venue_id)
    });

    let customFields = null;
    if ( props?.enquiry?.custom_field_values ) {
      customFields = {};
      props.enquiry.custom_field_values.forEach(v => {
        let valToUse = v.date_value !== null ? moment.utc(v.date_value) : (typeof v.custom_field_value === 'string' ? v.custom_field_value.replace(/["']/g, "") : v.custom_field_value);
        if (v.checkbox_value !== null) {
          valToUse = valToUse === 'No' ? 0 : valToUse === 'Yes' ? 1 : valToUse;
        }
        customFields[v.field_identifier] = valToUse;
      });
    }
    let linkedEnquiries = [];
    if (props?.enquiry?.linked_enquiries) {
      props.enquiry.linked_enquiries.forEach(linkedEnquiry => {
        linkedEnquiries.push({
          id: linkedEnquiry.id,
          ref_id: linkedEnquiry.ref_id,
          event_name: linkedEnquiry.event_name,
          ref_id_suffix: linkedEnquiry.ref_id_suffix
        })
      })
    }

    return {
      enquiry: props.enquiry,
      editEnquiry: true,
      isAgency: isAgency(state),
      isSuperAgent: isSuperAgent(state),
      isCorporate: isCorporate(state),
      isVenueUser: isVenue(state),
      venueIds: venueIds,
      offerRfpXpress: Boolean(offerRfpXpress),
      rfp_xpress_service: props.enquiry.rfp_xpress_service,
      corporateAccount: corporateAccount,
      user: user(state),
      userAgency: userAgencyObject(state),
      initialValues: {
        ...props.enquiry,
        date_from: moment(props.enquiry.date_from).utc(true).set({hour:0,minute:0,second:0,millisecond:0}).toISOString(),
        date_to: moment(props.enquiry.date_to).utc(true).set({hour:0,minute:0,second:0,millisecond:0}).toISOString(),
        booking_desk_id: props.enquiry && props.enquiry.corporate_specific_data ? props.enquiry.corporate_specific_data.booking_desk_id : null,
        agency_booking_desk_id: props.enquiry && props.enquiry.corporate_specific_data ? props.enquiry.corporate_specific_data.agency_booking_desk_id : null,
        // is_trinity_enquiry: props.enquiry && props.enquiry.corporate_specific_data ? props.enquiry.corporate_specific_data.is_trinity_enquiry : false,
        brief_location: props.enquiry ? props.enquiry.brief_preferences?.location : '',
        brief_event_type: props.enquiry? props.enquiry.brief_preferences?.event_type : '',
        brief_budget: props.enquiry ? props.enquiry.brief_preferences?.budget : '',
        custom_fields: customFields,
        syndicate_rooms: props.enquiry.syndicate_rooms,
        linked_enquiries: linkedEnquiries
      },
      disableMaxDelegates
    }
  }
  return {
    isAgency: isAgency(state),
    isSuperAgent: isSuperAgent(state),
    isVenueUser: isVenue(state),
    enquiryFormDisabled: enquiryFormDisabled,
    showFormToggle: showFormToggle,
    offerRfpXpress: Boolean(offerRfpXpress),
    userAgency: userAgencyObject(state),
    user: user(state),
    rfp_xpress_service: false,
    venueIds: template?.venueIds,
    corporateAccount: corporateAccount,
    initialValues: {
      ...template?.enquiry,
      super_agency_code: superAgencyCode
    },
    disableMaxDelegates
  }
}

const Rfp = ({
               location,
               initialValues,
               editEnquiry = false,
               venueIds = [],
               isAgency,
               isSuperAgent,
               isVenueUser,
               enquiry,
               offerRfpXpress,
               corporateAccount,
               user,
               isCorporate,
               enquiryFormDisabled = false,
               showFormToggle = false,
               disableMaxDelegates,
               userAgency
}) => {
  const titleEl = useRef(null);
  const token = useSelector(tokenSelector);
  const submitErrors = useSelector(getFormSubmitErrors(form));
  const isInternal = useSelector(isInternalSelector);
  const errors = Object.keys(submitErrors);
  const values = useSelector(getFormValues(form)) || {};
  const [page, setPage] = useState(0);
  const [submitToAgent, setSubmitToAgent] = useState(false);
  const [submitToDraft, setSubmitToDraft] = useState(false);
  const [enquiryToSubmitted, setEnquiryToSubmitted] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [submitting, setSubmitting] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [showDateWarningMessage, setShowDateWarningMessage] = useState(false);
  const [requiresAuthorising, setRequiresAuthorising] = useState(false);
  const [requiresAuthorisingMissingEmailError, setRequiresAuthorisingMissingEmailError] = useState(false);
  const [maxVenues, setMaxVenues] = useState(false);
  const [company, setCompany] = useState(null);
  const params = useParams(location?.search, "venues");
  const [venuesCsv, setVenuesCsv] = useState(null);
  const [roomStyleParams] = useState({fetchIncludeAdmin: true, fetchAccommodationOnly: true})
  const stageTitle = editEnquiry ? `Edit your Event Brief - ${enquiry.ref_id}` : "Your Event Enquiry";
  const [fetchAccommodationOnly, setFetchAccommodationOnly] = useState({fetchAccommodationOnly: true});
  const dispatch = useDispatch();
  const [formDisabled, setFormDisabled] = useState(enquiryFormDisabled);

  useEffect(() => {
    dispatch(destroy(form));
  }, []);

  useEffect(() => {
    // use same logic as API EnquiryController::checkIfAuthIsRequired
    let requiresAuth = [];
    let ca = company && company.corporate_account ? company.corporate_account : corporateAccount;
    if ((values['number_of_delegates'] || values['maximum_number_of_delegates']) && ca.max_delegates_triggers_authorisation_workflow && ca.max_delegates_triggers_authorisation_workflow > 0) {
      if ((values['number_of_delegates'] >= ca.max_delegates_triggers_authorisation_workflow) || (values['maximum_number_of_delegates'] >= ca.max_delegates_triggers_authorisation_workflow)) {
        requiresAuth.push({'noOfDelegates': true})
      }
    }

    if (ca.custom_field_questions) {
      let questions =  JSON.parse(ca.custom_field_questions).filter((question) => question.fieldType === 'checkbox')
      questions.forEach((question) => {
        if (question.ifTrueTriggersAuthorisationWorkflow === true && typeof values['custom_fields'] !== 'undefined' && typeof values['custom_fields'][question.fieldIdentifier] !== 'undefined')
        {
          if (!!values['custom_fields'][question.fieldIdentifier]) {
            let requireAuthObj = {};
            requireAuthObj[question.fieldIdentifier] = true;
            requiresAuth.push(requireAuthObj)
          }
        }
        if (question.ifFalseTriggersAuthorisationWorkflow === true && typeof values['custom_fields'] !== 'undefined' && typeof values['custom_fields'][question.fieldIdentifier] !== 'undefined')
        {
          if (!!values['custom_fields'][question.fieldIdentifier] === false) {
            let requireAuthObj = {};
            requireAuthObj[question.fieldIdentifier] = true;
            requiresAuth.push(requireAuthObj)
          }
        }
      })
    }
    if (ca && requiresAuth.length > 0 ) {
      // if confidential don't require authorizing to reduce risk of leaks
      if (Boolean(values.is_highly_confidential)) {
        setRequiresAuthorising(false);
      } else {
        if (ca.authorising_email === null || ca.authorising_email === '') {
          setRequiresAuthorisingMissingEmailError(true)
        } else {
          setRequiresAuthorisingMissingEmailError(false)
        }
        if (enquiry && enquiry.enquiry_status.code === "DRAFT" || !enquiry)
          setRequiresAuthorising(true);
      }
    } else {
      setRequiresAuthorising(false);
    }
  }, [values, company])

  useEffect(() => {
    if (params && params.venues) {
      setVenuesCsv(params.venues)
    } else if (venueIds.length > 0) {
      setVenuesCsv(venueIds);
    }
  }, [params]);

  useEffect(() => {
    if (params && params['venues']) {
      pushCheckOutStep(page + 1, params['venues'], location.pathname + location.search)
    }
  }, [page, params, location?.pathname, location?.search]);

  const { loading, data: venues } = useService({
    initialData: [],
    initialLoading: false,
    service: getVenues,
    params: venuesCsv ? venuesCsv : null,
    trigger: venuesCsv && venuesCsv.length,
  });

  const hasVenues = typeof venues !== "undefined" && !!venues.length;

  const { loading : styleLoading, data: roomStyleOptions, error } = useService({
    service: getRoomStyles,
    trigger: true,
    params: roomStyleParams,
  });

  const removeVenueFromDraftEnquiry = venueId => {
    if (enquiry && enquiry.enquiry_status.code === ENQUIRY_STATUS.DRAFT) {
      let filteredVenues = venuesCsv.filter(id => {
        return Number(id) !== Number(venueId)
      })
      setVenuesCsv(filteredVenues)
    }
  }

  const addVenueToEnquiry = venueId => {
    let venuesArray = [];
    if (venuesCsv !== null) {
      venuesArray = venuesCsv;
    }
    venuesArray.push(venueId)
    setVenuesCsv(venuesArray)
  }

  const nextPage = () => {
    setCompleted(prev => {
      const done = [...prev];
      done[page] = true;
      return done;
    });
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleSubmitEnquiry = async data => {
    setSubmitting(true);
    setSubmitError(false);

    if(editEnquiry) {
      data.super_agency_code = null;
    }
    let venues = params.venues || venuesCsv;

    const { result, error } = await submitEnquiry(
      { ...data, venues: venues, files: attachments},
      token,
      editEnquiry,
      submitToAgent,
      enquiryToSubmitted,
      submitToDraft,
      requiresAuthorising
    );

    setSubmitting(false);

    if (error) {
      setSubmitError(true);
      titleEl.current.scrollIntoView();
      throw new SubmissionError(mapServerErrors(error));
    } else {
      if (result && params.venues) {
        pushSubmitEnquiry(result.data.id, params.venues)
      }
      setSubmitSuccess(true);
    }
  };

  const pages = getPages(
    form,
    nextPage,
    prevPage,
    errors,
    handleSubmitEnquiry,
    attachments,
    setAttachments,
    roomStyleOptions,
    hasVenues,
    initialValues,
    editEnquiry,
    setSubmitToAgent,
    setEnquiryToSubmitted,
    isAgency,
    isSuperAgent,
    isVenueUser,
    setSubmitToDraft,
    enquiry,
    setShowDateWarningMessage,
    maxVenues,
    company,
    setCompany,
    offerRfpXpress,
    requiresAuthorising,
    setRequiresAuthorising,
    disableMaxDelegates,
    userAgency
  );
  // if (!params || !params.venues || !params.venues.length) {
  //   return (
  //     <div className="py-5 container">
  //       <h1 className="mb-5">Request for proposal</h1>
  //       <p className="text-lg">
  //         Please complete you brief, and one of our agents will review and advise on suggested venues to contact for a response<br/>
  //         or click <Link to={'/advanced-search'}>here</Link> to search for a Venue.
  //       </p>
  //     </div>
  //   );
  // }
  //
  // if (loading) {
  //   return <Loading large />;
  // }
  //
  if (styleLoading) {
    return <Loading large />;
  }

  const submitSuccessMessage = () => {
    if (!venues && isAgency && !isSuperAgent) {
      return <>Your brief has been saved as Draft. When you are happy with the requirements please submit it to the venues. {" "}
        You can <Link to={isInternal || isAgency? "/admin/rfp" : "/admin/my-edge/your-rfps"}>view your RFPs here </Link>{" "}
      </>
    }
    if (enquiryToSubmitted) {
      return <>Thank you for your enquiry, your request for proposal has been submitted. You can{" "}
        <Link to={isInternal || isAgency ? "/admin/rfp" : "/admin/my-edge/your-rfps"}>view your RFPs here. </Link>{" "}
      </>
    } else if (submitToDraft || (!venues && !submitToAgent)) { // and draft
      return <>Your brief has been saved as Draft. When you are happy with the requirements please submit it to agent who will suggest suitable venues. {" "}
        You can <Link to={isInternal || isAgency ? "/admin/rfp" : "/admin/my-edge/draft-rfps"}>view your RFPs here </Link>{" "}
      </>
    } else if (editEnquiry && !submitToAgent) {
      return <>Thanks for editing the brief. It has passed on to the original user/agent who created this enquiry to submit. {" "}
        You can <Link to={isInternal || isAgency ? "/admin/rfp" : "/admin/my-edge/draft-rfps"}>view your RFPs here </Link>{" "}
      </>
    } else if (submitToAgent && !requiresAuthorising) {
      return <>Your brief has passed on to an agent who will suggest suitable venues. {" "}
        You can <Link to={isInternal || isAgency ? "/admin/rfp" : isCorporate ?  "/corporate/events/draft-rfps/" : '/admin/my-edge/your-rfps'}>view your RFPs here </Link>{" "}
      </>
    }else if (requiresAuthorising) {
      return <>Your brief has been submitted for Approval. {" "}
        You can <Link to={isInternal  || isAgency? "/admin/rfp" : isCorporate ?  "/corporate/events/draft-rfps/" : '/admin/my-edge/your-rfps'}>view your RFPs here </Link>{" "}
      </>
    }
    // original text (request-for-proposal?venues=)
    return <>Thank you for your enquiry, your request for proposal has been submitted. You can{" "}
      <Link to={isInternal || isAgency ? "/admin/rfp" : "/admin/my-edge/your-rfps"}>view your RFPs here </Link>{" "}
      but this is not editable at this stage now this has been submitted.
    </>
  }

  if (submitSuccess && page === 2) {
    return (
        <div className="bg-white">
          <div className="py-5 container">
            <h1 className="mb-5">
              {stageTitle}
            </h1>
            <p className="text-lg">
              {submitSuccessMessage()}
            </p>
          </div>
        </div>
    );
  }
  return (
    <div className="bg-white">
      <div className="py-5 container">
        <h1 className="mb-5" ref={titleEl}>
          {stageTitle}
        </h1>
        {isVenueUser ?
            <div className="bg-warning px-3 py-6 my-5 block font-bold text-white">
              <p className="mb-0">
                <i className="fas text-2lg fa-exclamation-triangle mr-3"></i>
                Please note your user type is unable to send an enquiry. If you would like to send an enquiry to a venue other than your own, please log out and register a unique email address, as an Individual User. <a className={'text-white'} style={{textDecoration: 'underline'}} href={`${window.BASE_URL}/other`}>Register here</a>
              </p>
            </div>
        : null}
        <Enquiry
          form={form}
          venues={venues}
          enquiry={enquiry}
          offerRfpXpress={offerRfpXpress}
          addVenueToDraftEnquiry={addVenueToEnquiry}
          removeVenueFromEnquiry={removeVenueFromDraftEnquiry}
          setSubmitToAgent={setSubmitToAgent}
          setFormDisabled={setFormDisabled}
          formDisabled={formDisabled}
          showFormToggle={showFormToggle}
        />
        {showDateWarningMessage ? (
                <div className='bg-warning px-3 py-6 my-5 block font-bold text-white'>
                  <p >Since you have changed start and/or end dates for an enquiry with an existing checksheet you now need to review the conference package, meeting room and accommodation dates for the checksheet to ensure they match the new date range</p>
                </div>
        ) : null}
        {requiresAuthorising ? (
           <AuthorisationTriggeredBanner missingEmailError={requiresAuthorisingMissingEmailError}/>
        ) : null}
        {!formDisabled ? (
          <>
            <ProgressBar
              stages={pages.map((p, i) => ({
                ...p,
                complete: completed[i],
              }))}
              currentStage={page}
            />
            {submitError && page === 2 && (
              <p className="text-lg text-warning">
                There was a problem submitting your request.
              </p>
            )}
            {page === 0 ? (
              <>
                <h3>Get started</h3>
                <p className="max-w-md">
                  {venues && venues.length > 0 ? (
                    <>
                      You only have to complete step 1 to send an enquiry to your selected venues.
                      <br />
                      However, if you provide more information initially it will help the proposal process.
                    </>
                  ) : (
                    <>
                      Please complete your brief, and one of our agents will review and advise on suggested venues to contact
                      for a response or click <Link to={'/advanced-search'}>here</Link> to search for a Venue.
                    </>
                  )}
                </p>
              </>
            ) : page === 1 ? (
              <>
                <h3>Bedrooms</h3>
                <p>
                  From here you can indicate how many rooms you require before, during and after your event
                </p>
              </>
            ) : (
              <>
                <h3>The last step</h3>
                <p>Please let us know if you have any of the below requirements</p>
              </>
            )}
            <Panel additionalClasses={formDisabled ? 'opacity-50' : ''}>{submitting ? <Loading large /> : pages[page].Component}</Panel>
          </>
        ) : null}
        {/*{venues && (*/}
        {/*  <VenueSlider venues={venues} />*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(Rfp);
